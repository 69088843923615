import { IUser } from '@nab/x-types';
import { constants } from 'tenants/constants';

import { TenantHelpers } from 'tenants/_strategy';

export class Helpers extends TenantHelpers {
  public override getErrorMessage(code: number | string): string {
    return constants.sdfdpilot.ERROR_MESSAGE_MAP[code] ?? constants.sdfdpilot.DEFAULT_LOGIN_ERROR_MESSAGE;
  }

  public override isPreviewEnv(user: IUser): boolean {
    if (window.location.hostname === constants.common.IB_PREVIEW_HOSTNAME) return true;
    if (!!user && !!user.tailoring)
      return user.tailoring.checkFlag(constants.sdfdpilot.LAUNCHDARKLY_FLAGS.PREVIEW_CHECK_ENABLED, false);
    return false;
  }
}
