import { getShellActions, IUser } from '@nab/x-spa-react';
import Cookies from 'js-cookie';
import { AuthConfigs } from 'lib/auth-strategies/auth.config';

import { constants } from '../tenants/constants';

import { configMgr } from './configMgr';
import { httpClient } from './httpClient';

/**
 * Handles the authentication event from the identity miniapp.
 * Sets session cookies, updates app configuration, and navigates to the specified URI.
 *
 * @param event - The authentication event object containing the bearer token
 * @param navigateUri - The URI to navigate to after successful authentication (default: '/portal')
 */
export async function handleAuthentication(event: any, navigateUri: string = '/portal') {
  const bearerToken = event[constants.common.BEARER_TOKEN];
  const isLoginPath = /\/login/.test(window.location.pathname);

  if (bearerToken && isLoginPath) {
    const { navigate, updateApps } = getShellActions();

    Cookies.set(constants.common.USL_SESSION_COOKIE_ID, bearerToken, { path: constants.common.COOKIE_PATH });
    Cookies.set(constants.common.USL_SESSION_COOKIE_IS_AUTHENTICATED, 'true', { path: '/' });

    const dci = event[constants.common.DAF_DCI_HASH];
    if (dci) {
      // If DCI - Discreet Customer Identifier is present in the event use that to identify against the user context in launchdarkly
      Cookies.set(constants.common.USL_SESSION_COOKIE_DCI, dci);
      const authenticatedLDUser: Partial<IUser> = {
        id: dci,
        idHash: dci,
        authenticated: true,
        context: {
          updateLDContext: true
        },
        details: event.identityInfo
      };
      try {
        await updateApps({ authConfig: AuthConfigs.getInstance().postLoginAuthConfig, user: authenticatedLDUser });
      } catch (error) {
        console.warn('cannot update apps', error);
      }
    } else {
      // If no dci just update the postLoginAuth Config
      await updateApps({ authConfig: AuthConfigs.getInstance().postLoginAuthConfig });
    }

    navigate(navigateUri);
  }
}

/**
 * Revokes the authentication token.
 *
 * @param currentToken - The token to revoke (optional, uses cookie if not provided)
 */
export async function revokeToken(currentToken?: string) {
  const token = currentToken || Cookies.get(constants.common.USL_SESSION_COOKIE_ID);

  if (token) {
    const requestUrl = configMgr.get('KONG_DOMAIN') + configMgr.get('REVOKE_TOKEN_PATH');

    await httpClient.post(
      requestUrl,
      {
        client_id: configMgr.get('AUTH_CLIENT_ID'),
        token,
        token_type_hint: constants.common.TOKEN_TYPE_HINT
      },
      {
        headers: {
          'Content-Type': 'application/json'
        },
        'axios-retry': {
          retries: 2,
          retryCondition: error => error?.response?.status !== 200
        }
      }
    );
  }
}

/**
 * Clears all session information including cookies and storage.
 * Updates app configuration to pre-login state.
 */
export async function clearSessionInfo() {
  // Clearing session for Identity Miniapp
  sessionStorage.removeItem(constants.common.BEARER_TOKEN);

  // Clearing session token
  Cookies.remove(constants.common.USL_SESSION_COOKIE_IS_AUTHENTICATED);
  Cookies.remove(constants.common.USL_SESSION_COOKIE_ID);
  Cookies.remove(constants.common.USL_SESSION_COOKIE_DCI);

  const { updateApps } = getShellActions();

  await updateApps({ authConfig: AuthConfigs.getInstance().preLoginAuthConfig });
}

/**
 * Retrieves the authentication token from cookies.
 *
 * @returns The bearer token string if available, otherwise an empty string
 */
export function retrieveToken(): string {
  const token = Cookies.get(constants.common.USL_SESSION_COOKIE_ID);

  return typeof token === 'string' ? `Bearer ${token}` : '';
}

/**
 * Checks if the current session is valid.
 *
 * @returns True if both the session cookie exists and is authenticated, false otherwise
 */
export function isSessionValid(): boolean {
  const sessionCookie = Cookies.get(constants.common.USL_SESSION_COOKIE_ID);
  const isAuthenticated = Cookies.get(constants.common.USL_SESSION_COOKIE_IS_AUTHENTICATED);

  return isAuthenticated === 'true' && typeof sessionCookie === 'string';
}
