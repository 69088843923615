import React, { useMemo } from 'react';

import { Tenant } from 'tenants/_strategy';

import { StyledFooter, StyledFooterContainer, StyledText, StyledTextWrapper } from './Footer.style';

export interface FooterProps {}

const DefaultFooter: React.FC<{ text: string }> = ({ text }) => {
  const cleanText = text?.replace('©', '').trim();

  return (
    <StyledTextWrapper>
      <StyledText tabIndex={0} role="contentinfo" aria-label={cleanText}>
        {text}
      </StyledText>
    </StyledTextWrapper>
  );
};

const Footer: React.FC<FooterProps> = () => {
  const { text, CustomFooter } = useMemo(() => Tenant.instance().core.footerConfig(), []);

  return (
    <StyledFooter>
      <StyledFooterContainer>{CustomFooter ? <CustomFooter /> : <DefaultFooter text={text} />}</StyledFooterContainer>
    </StyledFooter>
  );
};

export default Footer;
