import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import { Header, HeaderEventName, MenuItemType } from '@nab/nui-react-molecule';
import { MenuNode, useActions, useUser } from '@nab/x-spa-react';

import { setFocus } from 'helpers/setFocus';

import { useLogoutCleanup } from 'hooks/useLogout';
import { usePrompt } from 'hooks/usePrompt';

import { IHeaderConfig, Tenant } from 'tenants/_strategy';
import { ActiveAppInstance } from 'tenants/types';

export interface GlobalHeaderProps {
  activeAppInstance?: ActiveAppInstance;
  mainRef?: MutableRefObject<any>;
  navMgrMenu?: MenuNode[];
  isAuthenticated?: boolean;
}

const GlobalHeader: React.FC<GlobalHeaderProps> = ({
  activeAppInstance,
  mainRef,
  navMgrMenu,
  isAuthenticated = false
}) => {
  const location = window.location;
  const tenant = Tenant.instance();

  const user = useUser();
  const headerRef = useRef(null);

  const prompt = usePrompt();
  const { logoutUser } = useLogoutCleanup();
  const { addEventListener, removeEventListener, navigate } = useActions();

  const headerConfig = useMemo(() => tenant.core.headerConfig(), [tenant.core]);
  const isHamburgerButtonVisible = useMemo(() => {
    if (isAuthenticated) {
      return true;
    }

    return headerConfig?.preLoginItems.length > 0;
  }, [headerConfig?.preLoginItems.length, isAuthenticated]);

  const handleSkipToMainContent = useCallback(() => {
    const appId = activeAppInstance?.app?.id;

    if (appId) {
      setFocus(mainRef, appId, true);
    }
  }, [mainRef, activeAppInstance]);

  const handleLogoutButton = useCallback(() => {
    tenant.events.handleLogoutButton(logoutUser, prompt);
  }, [tenant.events, logoutUser, prompt]);

  const onNavigate = useCallback(
    (item: MenuNode) => navigate(`${activeAppInstance?.route?.parentPath}${item.route}`),
    [activeAppInstance?.route?.parentPath, navigate]
  );

  const getDocumentTitle = useCallback((appTitle: string, headerConfig: IHeaderConfig) => {
    const { hideAppTitle, tabTitle } = headerConfig;
    if (hideAppTitle || !appTitle) return tabTitle;
    return `${appTitle} - ${tabTitle}`;
  }, []);

  useEffect(() => {
    window.document.title = getDocumentTitle(activeAppInstance?.app?.title, headerConfig);
  }, [getDocumentTitle, headerConfig, activeAppInstance, headerConfig.headerTitle.title]);

  useEffect(() => {
    addEventListener(HeaderEventName.LOGOUT, handleLogoutButton);
    addEventListener(HeaderEventName.SKIP_TO_MAIN_CONTENT, handleSkipToMainContent);

    return () => {
      removeEventListener(HeaderEventName.LOGOUT);
      removeEventListener(HeaderEventName.SKIP_TO_MAIN_CONTENT);
    };
  }, [addEventListener, handleSkipToMainContent, handleLogoutButton, logoutUser, removeEventListener, user]);

  return (
    <Header
      // @ts-ignore
      history={{ location }}
      mainRef={mainRef}
      headerRef={headerRef}
      useActions={useActions as any}
      isAuthenticated={isAuthenticated}
      isHamburgerButtonVisible={isHamburgerButtonVisible}
      onNavigate={onNavigate}
      navMgrMenu={navMgrMenu}
      hideNavMgr={headerConfig.hideNavMenu && isAuthenticated}
      rootPath={activeAppInstance?.route?.parentPath || ''}
      brandConfig={{
        desktopLogo: headerConfig.headerLogo.desktop,
        mobileLogo: headerConfig.headerLogo.mobile,
        alt: headerConfig.headerTitle.alt,
        title: headerConfig.headerTitle.title,
        redirectUrl: headerConfig.headerTitle.redirectUrl
      }}
      skipToMainContentConfig={{
        title: 'Skip to main content',
        type: MenuItemType.EVENT_TRIGGER,
        eventName: HeaderEventName.SKIP_TO_MAIN_CONTENT,
        displayTitle: true,
        displayOnSmScreen: true,
        display: false
      }}
      profileSwitcherConfig={null}
      preLoginMenu={headerConfig.preLoginItems}
      postLoginMenu={headerConfig.postLoginItems}
    />
  );
};

export default GlobalHeader;
