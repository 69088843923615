import { ContextConfig, ReactRootInitConfig } from '@nab/x-spa-react';
import Cookies from 'js-cookie';
import { configMgr } from 'lib/configMgr';
import { getContext } from 'lib/navManagerHelpers';
import { IHeaderConfig, TenantCore } from 'tenants/_strategy';
import { constants } from 'tenants/constants';
import { WIDGET_NAME } from 'tenants/types';

import { headerConfig, initConfig } from '../configs';
import { NavManagerContextProviderExtended } from '../nav-manager/navManagerProviderExtended';

export class Core extends TenantCore {
  public override async rootInitConfig(): Promise<ReactRootInitConfig> {
    return await initConfig();
  }

  public override headerConfig(): IHeaderConfig {
    return headerConfig;
  }

  public override async spaContextProvider(contextId: string): Promise<ContextConfig> {
    try {
      const isRootSpa = contextId === constants.ib.ROOT_SPA;
      if (isRootSpa) {
        const navManagerUrl = configMgr.get('NAV_MANAGER_BASE_URL');
        const contexts: ContextConfig[] = await new NavManagerContextProviderExtended(navManagerUrl).getContexts(
          navManagerUrl
        );

        const rootContext = contexts.find(context => context.id === contextId);
        return rootContext;
      }

      const data = await getContext(contextId);

      if (contextId === WIDGET_NAME.LINKAGE_MINIAPP) {
        data?.apps.forEach((app: Record<string, Record<string, string>>) => {
          const identityInfo = JSON.parse(Cookies.get(constants.ib.IDENTITY_INFO) || '{}')?.username;

          app.appConfig = { ...app?.appConfig, nabId: identityInfo };
        });
      }

      return data;
    } catch (e) {
      throw new Error(`Failed to retrieve context ${contextId}\n${e}`);
    }
  }
}
