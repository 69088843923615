/* eslint-disable @typescript-eslint/no-unused-vars */

import { MenuItemType } from '@nab/nui-react-molecule';
import { ContextConfig } from '@nab/x-spa';
import { ReactRootInitConfig } from '@nab/x-spa-react';
import { configMgr } from 'lib/configMgr';
import { httpClient } from 'lib/httpClient';
import { IFooterConfig, IHeaderConfig } from 'tenants/_strategy';
import { ITenantCore } from 'tenants/_strategy/lib/types';

export class TenantCore implements ITenantCore {
  public async rootInitConfig(): Promise<ReactRootInitConfig> {
    throw new Error('Method not implemented.');
  }

  public headerConfig(): IHeaderConfig {
    return {
      headerTitle: {
        alt: 'NAB',
        title: 'NAB'
      },
      preLoginItems: [
        {
          title: 'Support',
          displayTitle: true,
          type: MenuItemType.ANCHOR,
          target: '_blank',
          url: 'https://www.nab.com.au',
          hasExternalIcon: true
        }
      ],
      postLoginItems: [
        {
          title: 'Support',
          displayTitle: true,
          type: MenuItemType.ANCHOR,
          target: '_blank',
          url: 'https://www.nab.com.au',
          hasExternalIcon: true,
          displayOnHamburgerMenu: true
        }
      ]
    };
  }

  public footerConfig(): IFooterConfig {
    return { text: '© National Australia Bank Limited' };
  }

  public spaContextProvider(contextId: string): Promise<ContextConfig> {
    throw new Error('Method not implemented.');
  }

  public async getContext(contextId: string): Promise<any> {
    const tenant = configMgr.get('TENANCY_NAME').toLowerCase();
    const resp = await httpClient.get(`config/${tenant}/${configMgr.get('ENVIRONMENT')}/context-${contextId}.json`);
    return resp.data;
  }
}
