import { configMgr } from 'lib/configMgr';
import { WIDGET_NAME } from 'tenants/types';
import { constants } from 'tenants/constants';
import { Tenant, TenantEffects } from 'tenants/_strategy';
import { IUser } from '@nab/x-types';
import { getShellServices } from '@nab/x-registries';
import Cookies from 'js-cookie';

import { requestLogout } from '../../../components/LoginSpa/utils/sessions';

export class Effects extends TenantEffects {
  public override loadChatWidget(setBioCatchCsid, user, embedWidgetApp, chatRef) {
    const url = configMgr.get('LOGOUT_URL');

    requestLogout(url);

    const intervalId = setInterval(async () => {
      try {
        const csid = await user.dataSecurity('bc').getData();
        if (csid) {
          window.sessionStorage.setItem(constants.ib.CUSTOMER_SESSION_ID, csid);
          setBioCatchCsid(csid);
          clearInterval(intervalId);
        }
      } catch (e) {
        console.error('error when loading chat widget miniapp', e);
      }
    }, 500);

    embedWidgetApp({
      widgetName: WIDGET_NAME.CHAT_PRELOGIN,
      ref: chatRef
    });

    return () => clearInterval(intervalId);
  }

  public confirmLogout(): boolean {
    return window.confirm(constants.common.LOGOUT_CONFIRMATION_MESSAGE);
  }

  public async logout(user: IUser, redirectUri: string): Promise<void> {
    Tenant.instance().session.clear();

    const shouldLogoutClassic = configMgr.get('SHOULD_LOGOUT_CLASSIC');
    const sessionLogoutRequestUrl = configMgr.get('LOGOUT_URL');

    if (shouldLogoutClassic) {
      requestLogout(sessionLogoutRequestUrl);
    }

    if (redirectUri) {
      window.location.href = redirectUri;
    }

    try {
      const csid = await user.dataSecurity('bc').getData();
      await Tenant.instance().session.revoke(csid);
    } catch (err) {
      console.error('Error: revoke error', err);
    }
  }

  public override async updateAnalyticsData() {
    const services = getShellServices();
    const analyticsManager = services.analyticsMgr;
    const analyticsSessionId: string | null = Cookies.get(constants.ib.DAF_SSC_HASH);
    const firstLoginFlag: boolean = window.sessionStorage.getItem(constants.ib.IS_FIRST_LOGIN) === 'Y';
    if (analyticsSessionId) {
      await analyticsManager.updatePage({
        pageName: 'nab:ib-platform',
        server: window.location.hostname,
        site: 'nab',
        siteType: 'ib-platform',
        siteSection: 'ib-platform',
        siteSubsection: '',
        sysEnv: window.navigator.userAgent
      });
      // @ts-ignore
      window.digitalData['user'] = {
        loggedInStatus: 'logged-in',
        ssc: analyticsSessionId,
        userEvent: firstLoginFlag ? 'logged-in' : undefined
      };
    }
  }
}
