import React, { useCallback } from 'react';

import { useOverlay } from 'components/common/Prompt/OverlayProvider';
import PromptModal, { PromptAction } from 'components/common/Prompt/Prompt';

export const usePrompt = () => {
  const overlay = useOverlay();

  return useCallback<PromptAction>(
    async options => {
      return new Promise<boolean>(resolve => {
        overlay.present(actions => (
          <PromptModal
            {...options}
            renderRoot={overlay.renderRoot}
            onComplete={result => {
              actions.close();
              resolve(result);
            }}
          />
        ));
      });
    },
    [overlay]
  );
};
