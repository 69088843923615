import { ContextConfig, ReactRootInitConfig } from '@nab/x-spa-react';
import { spaContextProvider } from 'lib/navManagerHelpers';
import { IFooterConfig, IHeaderConfig, TenantCore } from 'tenants/_strategy';
import { constants } from 'tenants/constants';

import { footerConfig, headerConfig, initConfig } from '../configs';

export class Core extends TenantCore {
  public override async rootInitConfig(): Promise<ReactRootInitConfig> {
    return await initConfig();
  }

  public override headerConfig(): IHeaderConfig {
    return headerConfig;
  }

  public override footerConfig(): IFooterConfig {
    return footerConfig;
  }

  public override async spaContextProvider(contextId: string): Promise<ContextConfig> {
    const isRootSpa = contextId === constants.jbp.ROOT_SPA;

    const context = await spaContextProvider(isRootSpa, contextId);

    return context;
  }
}
