/* eslint-disable max-statements */
import { TenantStrategy } from '../_strategy';

import { Core, Helpers, Events, Session, Effects } from './models';

export class SdfdPilotStrategy extends TenantStrategy {
  private _customEvents: Events;

  constructor() {
    super();

    this.core = new Core();
    this.effects = new Effects();
    this.actions = new Session();
    this.helpers = new Helpers();

    this._customEvents = new Events();
  }

  public get events() {
    return this._customEvents;
  }
}
