import { Container, media } from '@nab/nui-react';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledFooter = styled.footer`
  height: auto;
  min-height: ${({ theme }) => theme.customStyle.minHeightFooter};
  background-color: ${({ theme }) => theme.color.backgroundFooter};
  color: ${({ theme }) => theme.color.white};
  z-index: ${({ theme }) => theme.zIndex.fixed};
`;

export const StyledFooterContainer = styled(Container)`
  min-height: ${({ theme }) => theme.customStyle.minHeightFooter};
`;

export const StyledTextWrapper = styled.div`
  min-height: ${({ theme }) => theme.customStyle.minHeightFooter};

  display: flex;
  align-items: center;
  justify-content: center;

  ${media.md`
    justify-content: flex-start;
  `}
`;

export const StyledText = styled.p`
  padding-left: 0px;

  ${media.md`
    padding-left: ${rem('8px')};
  `}
`;
