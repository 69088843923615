/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from '@nab/nui-react';
import { SpaProps, useActions, useUser } from '@nab/x-spa-react';
import GlobalHeader from 'components/GlobalHeader/GlobalHeader';
import { TenantThemeProvider, Viewport } from 'components/common';
import { useLogoutCleanup } from 'hooks/useLogout';
import Cookies from 'js-cookie';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tenant } from 'tenants/_strategy';
import { constants } from 'tenants/constants';
import { IbStrategy } from 'tenants/internet-banking';

import LinkageStub from './stubs/LinkageStub';

export const LinkageSpa: React.FC<SpaProps> = ({ activeAppInstance, context }) => {
  const { addEventListener, navigate } = useActions();
  const { logoutUser } = useLogoutCleanup();
  const user = useUser();
  const mainRef = useRef(null);
  const isStubEnabled = false;
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const errorSlotOverride = (error: string) => {
    console.warn('Loading error', error);
    Cookies.set(constants.ib.CAN_SWITCH, 'false');
    navigate(constants.ib.ACCOUNTS_SUMMARY_PATH);
  };

  useEffect(() => {
    const tenant = Tenant.instance() as IbStrategy;

    const unsubscribedLinkingEnd = addEventListener(tenant.events.ref.linkage.USER_LINKING_ENDED, event =>
      (Tenant.instance() as IbStrategy).events.handleLinkageMiniappLinkageEnded(event, user)
    );

    const unsubscribedLinkingError = addEventListener(tenant.events.ref.linkage.USER_LINKING_ERROR, event =>
      (Tenant.instance() as IbStrategy).events.handleLinkageMiniappLinkageError(event, logoutUser)
    );

    return () => {
      unsubscribedLinkingEnd();
      unsubscribedLinkingError();
    };
  }, [addEventListener, logoutUser, navigate]);

  useEffect(() => {
    setIsAuthenticated(Tenant.instance().session.isValid());
  }, []);

  useEffect(() => {
    if (!!activeAppInstance && isAuthenticated) {
      Tenant.instance().effects.updateAnalyticsData();
    }
  }, [activeAppInstance, isAuthenticated]);

  const viewport = useMemo(
    () => (
      <Container>
        <Viewport activeAppInstance={activeAppInstance} errorSlotOverride={errorSlotOverride} />
      </Container>
    ),
    [activeAppInstance, errorSlotOverride]
  );

  const menu = context?.menu || [];

  return (
    <TenantThemeProvider>
      <GlobalHeader
        activeAppInstance={activeAppInstance}
        mainRef={mainRef}
        navMgrMenu={menu}
        isAuthenticated={isAuthenticated}
      />
      <main ref={mainRef} tabIndex={-1} data-testid="linkage-spa">
        {isStubEnabled ? <LinkageStub /> : viewport}
      </main>
    </TenantThemeProvider>
  );
};
