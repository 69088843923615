import { AppDRUMStrategy, LaunchDarklyFlagStrategy, UserType } from '@nab/nab-x-sdk-browser';
import {
  DafInMemoryCacheStrategy,
  DefaultAuthConfig,
  ReactRootInitConfig,
  DafEmployeeSsoAuthStrategy,
  DafPopupStrategy,
  NullAuthStrategy,
  DafExchangeStrategy,
  NavManagerDataSourceStrategy,
  DataSourceInMemoryCacheStrategy
} from '@nab/x-spa-react';
import GlobalSpa from 'components/GlobalSpa/GlobalSpa';
import { AuthConfigs } from 'lib/auth-strategies/auth.config';
import { configMgr } from 'lib/configMgr';
import { Tenant } from 'tenants/_strategy';

import { constants } from 'tenants/constants';

import { SdfdBrandConfigWithDafTenancy } from '../types';

export const initConfig = async (): Promise<ReactRootInitConfig> => {
  const userId = Tenant.instance().session.userId();

  const environment = configMgr.get('ENVIRONMENT'); // 'prod', 'sit1', 'dev', 'local' etc

  const authConfigs = AuthConfigs.getInstance({
    environment,
    clientId: configMgr.get('DAF_AUTH_CLIENT_ID'),
    scopes: configMgr.get('DAF_AUTH_SCOPE')
  });

  const isLocalhost = window.location.hostname === 'localhost';
  const dafAuthUrl = `${configMgr.get('DAF_ENDPOINT')}/v1/idm/oauth/token`;

  const authConfig = {
    ...authConfigs?.preLoginAuthConfig,
    context: 'internal',
    grantType: '',
    requestTokenOnLoad: true,
    isSeed: false,
    scope: configMgr.get('DAF_REGISTERED-OAUTH-SCOPE')
  };

  return {
    // React component which provides the main layout for the shell
    rootComponent: GlobalSpa,

    // Named set of apps and menu items which live in this shell
    rootContextId: 'root',

    // Default auth config inherited by all apps which don't explicitly override it
    authConfig: authConfig as DefaultAuthConfig,
    authStrategies: isLocalhost
      ? [new NullAuthStrategy()]
      : [
          new DafEmployeeSsoAuthStrategy({
            clientId: configMgr.get('DAF_REGISTERED_OAUTH_CLIENT_ID'), // client created of the shell
            responseType: 'code id_token',
            displayStrategy: new DafPopupStrategy(),
            redirectUri: configMgr.get('OAUTH_REDIRECT_URL'),
            isSeed: true
          }),
          new DafExchangeStrategy(dafAuthUrl)
        ],
    // Defines how tokens are cached & validated
    authTokenCacheStrategy: new DafInMemoryCacheStrategy(),

    // Defines Data Source configuration
    dataSourceCacheStrategy: new DataSourceInMemoryCacheStrategy(),
    dataSourceStrategies: [
      new NavManagerDataSourceStrategy({
        dataSource: 'navmgr',
        endpoint: `${configMgr.get('NAV_MANAGER_BASE_URL')}${constants.sdfd.NAVMGR_MEGAMENU_URL}`,
        metadata: { contextId: configMgr.get('NAV_MANAGER_CONTEXT_ID') },
        prefetch: false,
        whitelist: ['SHELL']
      })
    ],

    // Defines how context config is retrieved (eg. from an API, JSON file on disk etc...)
    spaContextProvider: Tenant.instance().core.spaContextProvider,

    userConfig: {
      authenticated: false,
      id: userId,
      org: 'NAB',
      type: (authConfig?.context === 'internal'
        ? constants.common.BANKER_USER_TYPE
        : constants.common.CUSTOMER_USER_TYPE) as UserType,
      sessionId: null,
      context: {
        shell: constants.common.CUSTOMER_USER_TYPE
      }
    },

    servicesConfig: {
      rum: {
        strategy: new AppDRUMStrategy({
          libraryUrl: configMgr.get('APPDRUM_SCRIPT_TAG_SRC'),
          configValue: {
            appKey: configMgr.get('APPDRUM_APP_KEY'),
            adrumExtUrlHttp: configMgr.get('APPDRUM_EXT_URL_HTTP'),
            adrumExtUrlHttps: configMgr.get('APPDRUM_EXT_URL_HTTPS'),
            beaconUrlHttp: configMgr.get('APPDRUM_BEACON_URL_HTTP'),
            beaconUrlHttps: configMgr.get('APPDRUM_BEACON_URL_HTTPS'),
            xd: {
              enable: true
            },
            spa: {
              spa2: true
            }
          }
        })
      },
      flags: {
        // NAB SDFD LaunchDarkly Key
        strategy: new LaunchDarklyFlagStrategy(configMgr.get('LAUNCH_DARKLY_API_KEY', 'not-set!!!')),
        apiKey: '',
        initialData: undefined
      },
      interactionPrefix: constants.sdfd.INTERACTION_PREFIX
    },

    // Defines brandId and themeName, and miniapps will read it.
    brand: {
      id: constants.sdfd.BRAND_ID,
      themeName: constants.sdfd.THEME_NAME,
      dafTenancy: constants.sdfd.DAF_TENANCY
    } as SdfdBrandConfigWithDafTenancy,

    // Where should the shell be rendered?
    hostElement: document.querySelector('#appRoot')
  };
};
