import { MIN_HEIGH_CONTENT } from 'components/theme';
import styled from 'styled-components';

export const StyledContentContainer = styled.div`
  padding-bottom: 80px;
`;

export const GenericErrorWrapper = styled.div`
  min-height: ${({ theme }) => MIN_HEIGH_CONTENT(theme)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledMainWrapper = styled.main`
  min-height: ${({ theme }) => MIN_HEIGH_CONTENT(theme)};
`;
