import { IHeaderConfig } from 'tenants/_strategy';
import { HeaderEventName, MenuItemType } from '@nab/nui-react-molecule';
import { LogOutIcon } from '@nab/nui-react';
import Star from 'assets/images/star.svg';
import StarMore from 'assets/images/star_more.svg';

export const headerConfig: IHeaderConfig = {
  headerTitle: {
    alt: 'Adviser Registration',
    title: 'Adviser Registration'
  },
  headerLogo: {
    desktop: StarMore,
    mobile: Star
  },
  tabTitle: 'Adviser Registration',
  hideAppTitle: false,
  preLoginItems: [],
  postLoginItems: [
    {
      title: 'Logout',
      type: MenuItemType.BUTTON,
      eventName: HeaderEventName.LOGOUT,
      showBtnIconOnSmScreen: true,
      buttonIcon: LogOutIcon
    }
  ]
};
