import { DafAuthToken } from '@nab/x-spa-react';
import Cookie from 'js-cookie';

import { IB_TOKEN_ID } from './ib-token.constants';

export const getSeedToken = async (): Promise<string | DafAuthToken> => {
  return new Promise((resolve, reject) => {
    const esgToken = Cookie.get(IB_TOKEN_ID);
    if (typeof esgToken === 'string') {
      resolve(esgToken);
    } else {
      // If a token doesn't exist, kick off the IB authentication chain by hooking into it's overloaded axios.
      // The below event handler will trigger if things are successful.
      //logout(this.window);
      reject();
    }
  });
};
