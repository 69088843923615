import { ContextConfig, ReactRootInitConfig } from '@nab/x-spa-react';
import { configMgr } from 'lib/configMgr';
import { spaContextProvider } from 'lib/navManagerHelpers';
import { IHeaderConfig, TenantCore } from 'tenants/_strategy';
import { constants } from 'tenants/constants';
import { CustomSpaAppLoaderConfig } from 'tenants/types';

import { headerConfig, initConfig } from '../configs';

export class Core extends TenantCore {
  public override async rootInitConfig(): Promise<ReactRootInitConfig> {
    return await initConfig();
  }

  public override headerConfig(): IHeaderConfig {
    return headerConfig;
  }

  public override async spaContextProvider(contextId: string): Promise<ContextConfig> {
    const isRootSpa = contextId === constants.ar.ROOT_SPA;

    const context = await spaContextProvider(isRootSpa, contextId);

    if (isRootSpa) {
      context.apps.forEach((app: CustomSpaAppLoaderConfig) => {
        if (app.appMetaData.id === constants.ar.CHANGE_PASSWROD_MENU_ITEM) {
          app.appConfig = {
            ...app.appConfig,
            dafApiUrl: configMgr.get('DAF_ENDPOINT')
          };
        }
      });
    }

    return context;
  }
}
