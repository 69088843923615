/* eslint-disable @typescript-eslint/no-unused-vars */
import { IUser } from '@nab/x-types';
import Cookies from 'js-cookie';
import { ITenantEffects } from 'tenants/_strategy/lib/types';
import { constants } from 'tenants/constants';

export class TenantEffects implements ITenantEffects {
  public setBioCatchContext(user: IUser, context: any): void {
    user.dataSecurity('bc').setContext(context);
  }

  public loadChatWidget?(setBioCatchCsid: any, user: IUser, embedWidgetApp: any, chatRef: any): void {
    return null;
  }

  public confirmLogout?(): boolean {
    return true;
  }

  public async logout(user: IUser, redirectUri?: string, emitAnalytics?: boolean): Promise<void> {
    throw new Error('Method not implemented.');
  }

  public updateAnalyticsData(): void {
    // @ts-ignore
    window.digitalData['user'] = {
      loggedInStatus: 'logged-in',
      dci: Cookies.get(constants.common.USL_SESSION_COOKIE_DCI)
    };
  }
}
