import { getThemeDataByTenant } from 'components/theme';
import { configMgr } from 'lib/configMgr';
import React, { useCallback } from 'react';
import { ThemeProvider } from 'styled-components';

export interface TenantThemeProviderProps {
  children: React.ReactNode;
}

const TenantThemeProvider: React.FC = ({ children }) => {
  const tenant = configMgr.get('TENANCY_NAME').toLowerCase();
  const getThemeData = useCallback(themeData => getThemeDataByTenant(tenant, themeData), [tenant]);

  return (
    <ThemeProvider theme={getThemeData}>
      <>{children}</>
    </ThemeProvider>
  );
};

export default TenantThemeProvider;
