import { ErrorFillIcon } from '@nab/nui-react';
import { PromptAction } from 'components/common/Prompt/Prompt';
import { configMgr } from 'lib/configMgr';
import { TenantEvents } from 'tenants/_strategy';
import { constants } from 'tenants/constants';

export class Events extends TenantEvents {
  public override async handleLogoutButton(logoutUser: Function, prompt?: PromptAction): Promise<void> {
    const confirmed = await prompt({
      title: constants.jbp.LOGOUT_PROMPT_TITLE,
      confirmText: constants.jbp.LOGOUT_PROMPT_CONFIRM_TEXT,
      cancelText: constants.jbp.LOGOUT_PROMPT_CANCEL_TEXT,
      icon: ErrorFillIcon,
      iconColor: 'error'
    });

    if (confirmed) {
      const redirectUrl = configMgr.get('LOGOUT_REDIRECT_URL');
      logoutUser(redirectUrl);
    }
  }
}
