import { configMgr } from 'lib/configMgr';
import { constants } from 'tenants/constants';
import { Tenant, TenantEffects } from 'tenants/_strategy';
import { IUser } from '@nab/x-types';


import { requestLogout } from '../../../components/LoginSpa/utils/sessions';

export class Effects extends TenantEffects {


  public confirmLogout(): boolean {
    return window.confirm(constants.common.LOGOUT_CONFIRMATION_MESSAGE);
  }

  public async logout(user: IUser, redirectUri: string): Promise<void> {
    Tenant.instance().session.clear();

    const shouldLogoutClassic = configMgr.get('SHOULD_LOGOUT_CLASSIC');
    const sessionLogoutRequestUrl = configMgr.get('LOGOUT_URL');

    if (shouldLogoutClassic) {
      requestLogout(sessionLogoutRequestUrl);
    }

    if (redirectUri) {
      window.location.href = redirectUri;
    }

    try {
      const csid = await user.dataSecurity('bc').getData();
      await Tenant.instance().session.revoke(csid);
    } catch (err) {
      console.error('Error: revoke error', err);
    }
  }
}
