import { IUser } from '@nab/x-types';
import { Tenant, TenantEffects } from 'tenants/_strategy';

export class Effects extends TenantEffects {
  public async logout(user: IUser, redirectUri?: string, emitAnalytics: boolean = false): Promise<void> {
    try {
      await Tenant.instance().session.revoke();
      if (emitAnalytics) {
        user.emitEvent({
          eventType: 'page-view',
          data: {
            componentName: 'Portal Pay Mini App',
            section: 'logout:success',
            // @ts-ignore
            channel: window.digitalData.components.channel
          }
        });
      }
    } catch (err) {
      console.error('Error: revoke error', err);
      if (emitAnalytics) {
        user.emitEvent({
          eventType: 'page-view',
          data: {
            action: 'error-displayed',
            section: 'logout|error',
            sectionId: 'logout|error',
            errorType: 'screen',
            error: `Failed to revoke token: ${err}`,
            // @ts-ignore
            channel: window.digitalData.components.channel
          }
        });
      }
    } finally {
      Tenant.instance().session.clear();

      if (redirectUri) {
        window.location.href = redirectUri;
      }
    }
  }
}
