import {
  AuthStrategy,
  AuthToken,
  DafAuthConfig,
  DafSeedAuthConfig,
  DafAuthToken,
  getShellActions,
  DafTokenExchangeError
} from '@nab/x-spa-react';

import { EVENTS } from 'tenants/types';
import { constants } from 'tenants/constants';

import { Tenant } from '../../tenants/_strategy';

import { getSeedToken } from './ib-token-utils';
import { IB_TOKEN_ID } from './ib-token.constants';

export class IbAuthStrategy implements AuthStrategy<DafAuthConfig> {
  constructor(
    protected supportedAuthConfig?: DafAuthConfig,
    protected authenticated?: boolean
  ) {}

  /**
   * The config for the token being requested must match that which is supported by your seedTokenProvider
   */
  public supports(config: DafAuthConfig): boolean {
    return (
      config.tokenSource === 'DAF' &&
      Tenant.instance().session.isValid() &&
      (config.isSeed === true || ['root-spa', 'SHELL'].includes(config?.appMetaData?.id))
    );
  }

  public async authenticate(config: DafSeedAuthConfig): Promise<AuthToken<DafSeedAuthConfig>> {
    if (config.isInvalidSeedToken) {
      getShellActions().dispatchEvent(EVENTS.GLOBAL_ERROR_EVENT_NAME, {
        code: constants.globalAuthErrorCodes.INVALID_OR_MISSING_TOKEN_401
      });
      throw new Error(DafTokenExchangeError.INVALID_SEED_TOKEN);
    }

    const tokenResult: string | DafAuthToken = await getSeedToken();
    const fifteenMinutesExpiry: number = 15 * 60 * 1000; // 15 minutes * 60 seconds * 1000 milliseconds
    if (typeof tokenResult === 'string') {
      return {
        id: IB_TOKEN_ID,
        tokenValue: tokenResult,
        expires: Date.now() + fifteenMinutesExpiry,
        config
      };
    }
    if (tokenResult && typeof tokenResult === 'object') {
      return {
        ...tokenResult,
        config
      };
    }
    throw new Error(`Seed token provider did not return a token`);
  }
}
