import React, { useCallback } from 'react';

import { Button, ErrorFillIcon } from '@nab/nui-react';

import { ButtonGrid, GridBody, GridContainer, StyledModal } from './Prompt.style';

export interface PromptOptions {
  title: string;
  body?: string | JSX.Element;
  icon?: any;
  iconColor?: string;
  cancelText?: string;
  confirmText?: string;
  isCenterScreen?: boolean;
}

export type PromptAction = (options: PromptOptions) => Promise<boolean>;

export interface PromptModalProps extends PromptOptions {
  renderRoot: HTMLElement;
  onComplete: (result: boolean) => void;
}

const PromptModal: React.FC<PromptModalProps> = ({
  onComplete,
  renderRoot,
  title,
  body,
  icon = ErrorFillIcon,
  iconColor = 'primary',
  cancelText = 'Cancel',
  confirmText = 'Ok',
  isCenterScreen = true
}) => {
  const [isClosing, setIsClosing] = React.useState(false);

  const done = useCallback(
    (result: boolean) => {
      setIsClosing(true);
      onComplete(result);
    },
    [onComplete, setIsClosing]
  );

  return (
    <StyledModal
      id="promptModal"
      rootNode={renderRoot}
      beginClose={isClosing}
      onClose={() => done(false)}
      title={title}
      icon={icon}
      iconColor={iconColor}
      alignment="middle"
      hasClose
      hasEscapeClose
      hasBackdropClose
      isCenterScreen={isCenterScreen}
    >
      <GridContainer>
        {body && <GridBody>{body}</GridBody>}
        <ButtonGrid>
          {cancelText && (
            <Button
              id="modalCloseButton"
              type="button"
              label={cancelText}
              variant="secondary"
              onClick={() => done(false)}
            />
          )}
          {confirmText && (
            <Button
              id="modalAcceptButton"
              type="button"
              label={confirmText}
              variant="primary"
              onClick={() => done(true)}
            />
          )}
        </ButtonGrid>
      </GridContainer>
    </StyledModal>
  );
};

export default PromptModal;
