import { useBrand, useRUMUserDataProvider } from '@nab/nab-x-react';
import { AppDRumUserDataProvider } from '@nab/nab-x-sdk-browser';
import { GlobalStyle, Theme } from '@nab/nui-react';
import Footer from 'components/Footer/Footer';
import { configMgr } from 'lib/configMgr';
import React from 'react';

import { TenantThemeProvider, Viewport } from 'components/common';

import LeftMenu from 'components/DevSidePanel/LeftPanel';

import { ISpaProps } from 'tenants/types';

import { useHandleApiAuthError } from 'components/GlobalSpa/hooks/useHandleApiAuthError';

import { MenuAndViewport, StyledContentContainer, StyledRoot } from './GlobalSpa.style';

const GlobalSpa: React.FC<ISpaProps> = ({ activeAppInstance }) => {
  const { themeName } = useBrand();

  useRUMUserDataProvider(
    new AppDRumUserDataProvider({
      forError: {
        BSN: configMgr.get('APPDRUM_BSN')
      }
    })
  );
  useHandleApiAuthError();

  // Stop un-necessary re-renders when activeAppInstance is not ready.
  if (!activeAppInstance) return <></>;

  return (
    <>
      <GlobalStyle normalize borderBox />
      <Theme theme={themeName}>
        <TenantThemeProvider>
          <StyledRoot>
            {configMgr.get('EVENT_INSPECTOR_ENABLED') === 'true' && <LeftMenu />}
            <StyledContentContainer>
              <MenuAndViewport>
                <Viewport activeAppInstance={activeAppInstance} />
              </MenuAndViewport>
            </StyledContentContainer>
            <Footer />
          </StyledRoot>
        </TenantThemeProvider>
      </Theme>
    </>
  );
};

export default GlobalSpa;
