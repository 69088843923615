import { BaseTheme } from '@nab/nui-react';
import { deepMerge } from 'helpers/deepMerge';
import memoize from 'lodash/memoize';
import { constants } from 'tenants/constants';
import { TenantThemeData, ThemeValue } from 'theme';

// Spaces

/**
 * 0.5 rem
 */
export const SPACE_XS: ThemeValue = ({ theme }) => theme.spaces.xs;
/**
 * 1 rem
 */
export const SPACE_SM: ThemeValue = ({ theme }) => theme.spaces.sm;
/**
 * 1.5 rem
 */
export const SPACE_MD: ThemeValue = ({ theme }) => theme.spaces.md;
/**
 * 2 rem
 */
export const SPACE_LG: ThemeValue = ({ theme }) => theme.spaces.lg;
/**
 * 2.5 rem
 */
export const SPACE_XLG: ThemeValue = ({ theme }) => theme.spaces.xlg;
/**
 * 3 rem
 */
export const SPACE_XXLG: ThemeValue = ({ theme }) => theme.spaces.xxlg;

// Colors
export const GREY_10: ThemeValue = ({ theme }) => theme.color.grey10;
export const GREY_30: ThemeValue = ({ theme }) => theme.color.grey30;
export const GREY_50: ThemeValue = ({ theme }) => theme.color.grey50;
export const GREY_90: ThemeValue = ({ theme }) => theme.color.grey90;
export const GREY_96: ThemeValue = ({ theme }) => theme.color.grey96;
export const BLACK: ThemeValue = ({ theme }) => theme.color.black;
export const WHITE: ThemeValue = ({ theme }) => theme.color.white;
export const PRIMARY: ThemeValue = ({ theme }) => theme.color.primary;

// Fonts

export const SEMIBOLD: ThemeValue = ({ theme }) => theme.font.weight.semibold;

// Z-Index

export const ZINDEX_FIXED: ThemeValue = ({ theme }) => theme.zIndex.fixed;
export const ZINDEX_DROPDOWN = Object.assign(({ theme }) => theme.zIndex.dropdown, {
  minus:
    (value: number): ThemeValue =>
    ({ theme }) =>
      Number(theme.zIndex.dropdown) - value
}) as ThemeValue & { minus: (value: number) => ThemeValue };

export const MIN_HEIGH_CONTENT = (theme, hasNavMenu = false) => {
  const minHeightHeader = theme.customStyle?.minHeightDesktopHeader ?? '0px';
  const minHeightFooter = theme.customStyle?.minHeightFooter ?? '0px';
  const minHeightNavMenu = theme.customStyle?.minHeightNavMenu ?? '0px';

  if (hasNavMenu) {
    return `calc(100vh - ${minHeightHeader} - ${minHeightFooter} - ${minHeightNavMenu})`;
  }

  return `calc(100vh - ${minHeightHeader} - ${minHeightFooter})`;
};

export const getThemeDataByTenant = memoize((tenant: string, baseTheme: BaseTheme) =>
  deepMerge(baseTheme, tenantThemeData(baseTheme)['default'], tenantThemeData(baseTheme)[tenant])
);

export const tenantThemeData = (theme: BaseTheme): TenantThemeData => ({
  default: {
    color: {
      backgroundHeader: theme.color.black,
      backgroundFooter: theme.color.black
    },
    customStyle: {
      minHeightDesktopHeader: '65px',
      minHeightMobileHeader: '65px',
      minHeightDesktopHeaderImg: '32px',
      minWidthDesktopHeaderImg: '66px',
      minHeightMobileHeaderImg: '38px',
      minWidthMobileHeaderImg: '38px',
      minHeightPrompt: '352px',
      minHeightFooter: '64px',
      minHeightNavMenu: '50px'
    }
  },
  [constants.common.IB_TENANT]: {
    font: {
      family: {
        headingTitle: 'Epilogue, SourceSansPro, Helvetica, Arial, sans-serif'
      }
    }
  },
  [constants.common.NAB_PORTAL_PAY_TENANT]: {
    customStyle: {
      minHeightDesktopHeader: '56px',
      minHeightMobileHeader: '56px'
    }
  },
  [constants.common.ADVISER_REGISTRATION_TENANT]: {
    customStyle: {
      minHeightDesktopHeader: '56px',
      minHeightMobileHeader: '56px'
    }
  },
  [constants.common.JBWERE_PROSPECT_TENANT]: {
    color: {
      backgroundHeader: theme.color.primary,
      backgroundFooter: theme.color.primary
    },
    customStyle: {
      minHeightDesktopHeader: '65px',
      minHeightMobileHeader: '65px',
      minHeightDesktopHeaderImg: '26.53px',
      minWidthDesktopHeaderImg: '105px',
      minHeightMobileHeaderImg: '28px',
      minWidthMobileHeaderImg: '134px',
      minHeightPrompt: '352px',
      minHeightFooter: '96px',
      minHeightNavMenu: '0px'
    }
  }
});
