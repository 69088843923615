import { ITenantSession } from 'tenants/_strategy/lib/types';
import { clearSessionInfo, isSessionValid, retrieveToken, revokeToken } from 'lib/sessionHelpers';

/**
 * These are the default implementation of the follwing methods
 * if you would like to modify the behaviour of these functions it needs to be overridden in specifc tenant instance of session.
 */
export class TenantSession implements ITenantSession {
  public async clear(): Promise<void> {
    await clearSessionInfo();
  }

  public async revoke(currentToken?: string): Promise<void> {
    await revokeToken(currentToken);
  }

  public token(): string {
    return retrieveToken();
  }

  public isValid(): boolean {
    return isSessionValid();
  }

  public userId(): string {
    return '';
  }
}
