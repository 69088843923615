import {
  ContextConfig,
  getShellActions,
  Menu,
  ReactRootInitConfig,
  transformNavMenusToContexts
} from '@nab/x-spa-react';
import { getContext, getTitleFromMenuItems } from 'lib/navManagerHelpers';
import { IHeaderConfig, TenantCore } from 'tenants/_strategy';

import { CustomSpaAppLoaderConfig } from 'tenants/types';
import { constants } from 'tenants/constants';

import { headerConfig, initConfig } from '../configs';

interface IShellData {
  totalCount: number;
  menus: Menu[];
}

export class Core extends TenantCore {
  public override async rootInitConfig(): Promise<ReactRootInitConfig> {
    return await initConfig();
  }

  public override headerConfig(): IHeaderConfig {
    return headerConfig;
  }

  public override async spaContextProvider(contextId: string): Promise<ContextConfig> {
    const isRootSpa = contextId === constants.sdfd.ROOT_SPA;

    if (!isRootSpa) {
      try {
        const context = await getContext(contextId);
        return context;
      } catch (e) {
        const error = e instanceof Error ? e : new Error('Unknown error occurred');
        throw new Error(`Failed to retrieve context ${contextId}: ${error.message}`);
      }
    }

    try {
      const { getShellData } = getShellActions();
      const result = await getShellData<IShellData>({
        dataSource: 'navmgr',
        fallback: () => Promise.resolve(null)
      });

      // Early return if no result
      if (!result) {
        throw new Error('No data received from shell');
      }

      // Filter result with contextid
      result.menus = result.menus.filter(menu => menu.contextId === contextId);

      // Create a safe copy of the result using Object.create(null)
      const safeResult = { ...Object.create(null), ...result };

      // Process contexts in a more functional way
      const contexts = transformNavMenusToContexts(safeResult.menus).map(context => {
        const updatedContext = { ...context, id: contextId };

        // Process apps using map instead of forEach
        updatedContext.apps = updatedContext.apps.map(app => {
          const customApp = app as CustomSpaAppLoaderConfig;

          if (customApp.appMetaData) {
            customApp.appMetaData.title = getTitleFromMenuItems(customApp.appMetaData.route, updatedContext.menu);
          }

          if ('shellConfig' in customApp) {
            customApp.appConfig = {
              ...customApp.appConfig,
              ...customApp.shellConfig?.appConfig,
              shellConfig: customApp.shellConfig
            };
          }

          return customApp;
        });

        return updatedContext;
      });

      return contexts[0];
    } catch (error) {
      // Improved error handling with proper error object
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      console.error('SpaContextProvider error:', errorMessage);
      throw new Error(`Failed to process root context: ${errorMessage}`);
    }
  }
}
