import { Button, FatalError, RefreshIcon, UnpluggedIcon } from '@nab/nui-react';
import { useReactPath } from 'hooks/useReactPath';
import React, { useEffect } from 'react';
import { constants } from 'tenants/constants';
import { IGenericError } from 'tenants/types';

import { ErrorWrapper } from './GenericError.style';

const GenericError: React.FC<IGenericError> = ({
  errorTitle = constants.common.GENERIC_ERROR_MESSAGE_TITLE,
  errorDescription = constants.common.GENERIC_ERROR_MESSAGE_DESCRIPTION,
  errorButton = {
    label: 'Reload',
    variant: 'primary',
    styledAsLink: true
  },
  hideErrorButton = false
}) => {
  const pathname = useReactPath();

  useEffect(() => {
    if (document.querySelector('[data-component-id="FatalError"]')) {
      const h1 = document.querySelector('h1');
      h1.tabIndex = -1;
      h1.onfocus = () => {
        h1.style.outline = '0px transparent!important';
      };
      h1.focus();
    }
  }, [pathname]);

  const onReloadBtnClick = () => {
    window.location.reload();
  };

  return (
    <ErrorWrapper>
      <FatalError
        pictogram={UnpluggedIcon}
        heading={constants.common.ERROR_HEADING}
        paragraph={
          <p>
            {errorTitle}
            <br />
            {errorDescription}
          </p>
        }
        action={
          !hideErrorButton && (
            <Button
              styledAsLink={errorButton.styledAsLink}
              label={errorButton.label}
              variant={errorButton.variant}
              icon={RefreshIcon}
              onClick={onReloadBtnClick}
            />
          )
        }
      />
    </ErrorWrapper>
  );
};

export default GenericError;
