import { CallIcon, LinkIcon } from '@nab/nui-react';
import JBWereFooter from 'components/Footer/JBWereFooter/JBWereFooter';
import { IFooterConfig } from 'tenants/_strategy';

export const footerConfig: IFooterConfig = {
  text: '© JBWere Limited, AFSL and ACL 341162',
  links: [
    {
      title: 'Contact us',
      href: 'https://www.jbwere.com.au/contact/contact-us',
      target: '_blank',
      icon: CallIcon,
      iconToRight: true,
      isOnDarkBg: true
    },
    {
      title: 'Privacy Policy',
      href: 'https://www.jbwere.com.au/-/privacy',
      target: '_blank',
      icon: LinkIcon,
      iconToRight: true,
      isOnDarkBg: true
    },
    {
      title: 'Terms and conditions',
      href: 'https://www.jbwere.com.au/-/terms-conditions',
      target: '_blank',
      icon: LinkIcon,
      iconToRight: true,
      isOnDarkBg: true
    },
    {
      title: 'Financial Services Guide',
      href: 'https://www.jbwere.com.au/resources/financial-services-guides',
      target: '_blank',
      icon: LinkIcon,
      iconToRight: true,
      isOnDarkBg: true
    }
  ],
  CustomFooter: JBWereFooter
};
