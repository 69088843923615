import { Link } from '@nab/nui-react';
import React, { useMemo } from 'react';

import { FooterLink, Tenant } from 'tenants/_strategy';

import {
  StyledFooterContent,
  StyledLinkWrapper,
  StyledMenuLink,
  StyledRowWrapper,
  StyledText,
  StyledTextWrapper
} from './JBWereFooter.style';

const CopyRightText: React.FC<{ text: string }> = ({ text }) => {
  const cleanText = text?.replace('©', '').trim();

  return (
    <StyledText tabIndex={0} role="contentinfo" aria-label={`${cleanText}`}>
      {text}
    </StyledText>
  );
};

const MenuLink: React.FC<{ links: FooterLink[] }> = ({ links }) => {
  return (
    <StyledMenuLink>
      {links.map((link, index) => (
        <Link
          key={index}
          href={link.href}
          target={link.target}
          variant={link.variant}
          icon={link.icon}
          iconToRight={link.iconToRight}
          color={link.color}
          standalone={link.standalone}
          isOnDarkBg={link.isOnDarkBg}
        >
          {link.title}
        </Link>
      ))}
    </StyledMenuLink>
  );
};

const JBWereFooter: React.FC = () => {
  const { text, links } = useMemo(() => Tenant.instance().core.footerConfig(), []);

  return (
    <StyledFooterContent>
      <StyledRowWrapper>
        <StyledLinkWrapper>
          <MenuLink links={links} />
        </StyledLinkWrapper>
        <StyledTextWrapper>
          <CopyRightText text={text} />
        </StyledTextWrapper>
      </StyledRowWrapper>
    </StyledFooterContent>
  );
};

export default JBWereFooter;
