import { ContextConfig, MenuNode, NavManagerContextProvider } from '@nab/x-spa-react';
import { CustomSpaAppLoaderConfig } from 'tenants/types';

import { configMgr } from './configMgr';
import { httpClient } from './httpClient';

/**
 * Get title from menu's item
 * @param {MenuNode[]} menu - Array of menu items
 * @param {string} route - Route path
 * @returns {string} The title of the menu item
 */
export const getTitleFromMenuItems = (route: string, menu: MenuNode[] = []): string => {
  const currentItem = menu.find(item => item.route === route);
  return currentItem?.label;
};

/**
 * Retrieve and process the root context
 * @param {string} contextId - The context ID
 * @param {boolean} shouldSetRootContextId - Flag to determine if the root context ID should be set
 * @returns {Promise<ContextConfig>} The root context
 */
export const getRootContext = async (
  contextId: string,
  shouldSetRootContextId: boolean = true
): Promise<ContextConfig> => {
  let contexts: ContextConfig[];

  try {
    const navManagerUrl = configMgr.get('NAV_MANAGER_BASE_URL');

    contexts = await new NavManagerContextProvider(navManagerUrl).getContexts([
      configMgr.get('NAV_MANAGER_CONTEXT_ID')
    ]);
  } catch (e) {
    throw new Error('Failed to get menus', e);
  }

  if (shouldSetRootContextId) {
    contexts.forEach(context => {
      context.id = contextId;
      context.apps.forEach(app => {
        const customSpaAppLoaderConfig = app as CustomSpaAppLoaderConfig;
        const { appMetaData } = customSpaAppLoaderConfig;

        if (appMetaData) {
          appMetaData.title = getTitleFromMenuItems(appMetaData.route, context.menu);
        }

        if ('shellConfig' in app) {
          app.appConfig = { ...app.appConfig, ...app.shellConfig?.appConfig, shellConfig: app.shellConfig };
        }
        return app;
      });
      return context;
    });
  }

  const rootContext = contexts.find(context => context.id === contextId);

  return rootContext;
};

/**
 * Retrieve context for a specific tenant and environment
 * @param {string} contextId - The context ID
 * @returns {Promise<any>} The context data
 */
export const getContext = async (contextId: string): Promise<any> => {
  const tenant = configMgr.get('TENANCY_NAME').toLowerCase();
  const env = configMgr.get('ENVIRONMENT');
  const baseUrl = `config/${tenant}/${env}/context-${contextId}.json`;

  const resp = await httpClient.get(baseUrl);

  return resp.data;
};

/**
 * Provide the appropriate context based on whether it's a root SPA or not
 * @param {boolean} isRootSpa - Flag indicating if it's a root SPA
 * @param {string} contextId - The context ID
 * @param {boolean} shouldSetRootContextId - Flag to determine if the root context ID should be set
 * @returns {Promise<any>} The context data
 */
export const spaContextProvider = async (
  isRootSpa: boolean,
  contextId: string,
  shouldSetRootContextId: boolean = true
): Promise<any> => {
  try {
    return isRootSpa ? await getRootContext(contextId, shouldSetRootContextId) : await getContext(contextId);
  } catch (e) {
    throw new Error(`Failed to retrieve context ${contextId}\n${e}`);
  }
};
