export const constants = {
  npp: {
    ROOT_SPA: 'nab-portal-pay-miniapp',
    BAKER: 'baker',
    INTERACTION_PREFIX: 'NABPORTALPAY',
    LINKAGE_PATH: '/linkage',
    MINIAPP_IFRAME: 'miniapp-iframe',
    NAVMGR_MEGAMENU_URL: '/menus',
    COOKIE_PATH: '/',
    TOKEN_TYPE_HINT: 'access_token',
    LOADING_TEXT: 'Loading...',
    BC_BRAND_VALUE: 'Redstar',

    // BRAND CONFIG
    BRAND_ID: 'baker',
    THEME_NAME: 'nab',
    DAF_TENANCY: 'baker',

    // FLAGS
    LAUNCHDARKLY_FLAGS: {
      ACCESS_TO_IB_SHELL: 'access-to-ib-shell',
      PREVIEW_CHECK_ENABLED: 'preview-check-enabled'
    } as const,
    SUPPORT_LINK: 'https://www.nab.com.au/business/portal-pay',
    DEFAULT_LOGIN_ERROR_MESSAGE: 'NAB Portal Pay is temporarily unavailable. Try again later.',

    ERROR_MESSAGE_MAP: {
      201015: `Your session has timed out due to inactivity - you'll need to log in again.`,
      201014: 'Your session has been disconnected. Please login again.',
      201001: 'The NAB ID or password you entered is incorrect. <br/>Remember, your password is case sensitive.',
      400106: `There are no accounts associated with your NAB ID. You'll need to give us a call on 13 22 65.`,
      15502:
        'Your access has been locked. You need to <a href="/nabib/password_reset.ctl" onKeyDown="javascript:event.cancelBubble=true" title="reset your password" class="link">reset your password</a>.',
      213033: `Your internet banking access is blocked. <br/>You'll need to call us on 13 22 65.`,
      201029: `You have been disconnected. Try again.`,
      201030: 'To ensure your security, we had to log you out. Try logging in again.'
    },

    LANGUAGE: {
      labels: {
        SECURITY: 'Security',
        HELP: 'Help',
        CONTACT_US: 'Contact us',
        LOCATE_US: 'Locate us',
        COPYRIGHT_TEXT: '© National Australia Bank Limited',
        LOGIN_TIPS: 'loginTips',
        SEARCH: 'Search',
        SEARCH_CLOSE: 'Close search',
        NOTIFICATION: 'Notification',
        PRINT: 'Print',
        LOGOUT: 'Logout',
        MAIN_MENU: 'Main Menu',
        SKIP_TO_MAIN_CONTENT: 'Skip to main content'
      } as const,
      title: {
        NAB_TITLE: 'Internet Banking'
      } as const
    }
  },
  ib: {
    ROOT_SPA: 'root-spa',
    INTERACTION_PREFIX: 'NABIB',
    LINKAGE_PATH: '/linkage',
    ACCOUNTS_SUMMARY_PATH: '/acctInfo_acctBal.ctl',
    MINIAPP_IFRAME: 'miniapp-iframe',
    NAVMGR_MEGAMENU_URL: '/menus',
    IDENTITY_INFO: 'identityInfo',
    SESSION_COOKIE: 'nabibSessionToken',
    DAF_SSC_HASH: 'daf-ssc',
    CUSTOMER_SESSION_ID: 'ibs-csId',
    IS_FIRST_LOGIN: 'isFirstLogin',
    IS_USER_LOGGED_IN: 'isUserLoggedIn',
    COOKIE_IS_AUTHENTICATED: 'ibs-isAuthenticated',
    SESSION_TIMESTAMP: `$nabibSessionTokenTimestamp`,
    CAN_SWITCH: 'can-switch',
    COOKIE_PATH: '/',
    TOKEN_TYPE_HINT: 'access_token',
    LOGIN_TIPS_MIN_HEIGHT: 40,
    LOADING_TEXT: 'Loading...',
    BC_BRAND_VALUE: 'Redstar',
    NOTIFICATION_SESSION_STORAGE_CONSTANT: 'hiddenNotificationCNs',
    NOTIFICATION_PAGE_VISITED: 'notificationPageVisited',
    CACHED_ALL_IB_NOTIFICATIONS: 'all_ib_notifications',
    CACHED_ACCOUNT_SUMMARY: 'ib_space_notification',
    CACHED_INVITATION_ACCOUNT: 'ib_space_invitation_account',

    // BRAND CONFIG
    BRAND_ID: 'ib',
    THEME_NAME: 'nab',
    DAF_TENANCY: 'ib',

    // Identity
    BIOCATCH_LOGIN_PAGE_CONTEXT: 'LOGIN',

    // IB classic form post
    FORM_METHOD_POST: 'POST',
    FORM_FIELD_TYPE_HIDDEN: 'hidden',

    LINKAGE_ERROR_EVENT_DISPLAY_CODE: '201029',
    PREVIEW_ACCESS_ERROR_EVENT_DISPLAY_CODE: '201031',

    LOCAL_STORAGE_ROLLOUT_FLAGS: 'ibs-rollout-features',

    // FLAGS
    LAUNCHDARKLY_FLAGS: {
      LOGIN_TIPS_IFRAME: 'login-tips-iframe',
      ACCESS_TO_IB_SHELL: 'access-to-ib-shell',
      LINKING_SWITCH_ABILITY: 'ib-linking-switch-ability',
      PREVIEW_CHECK_ENABLED: 'preview-check-enabled'
    } as const,

    DEFAULT_LOGIN_ERROR_MESSAGE: 'NAB Internet Banking is temporarily unavailable. Try again later.',
    ERROR_MESSAGE_MAP: {
      201015: `Your session has timed out due to inactivity - you'll need to log in again.`,
      201014: 'Your session has been disconnected. Please login again.',
      201001: 'The NAB ID or password you entered is incorrect. <br/>Remember, your password is case sensitive.',
      400106: `There are no accounts associated with your NAB ID. You'll need to give us a call on 13 22 65.`,
      15502:
        'Your access has been locked. You need to <a href="/nabib/password_reset.ctl" onKeyDown="javascript:event.cancelBubble=true" title="reset your password" class="link">reset your password</a>.',
      213033: `Your internet banking access is blocked. <br/>You'll need to call us on 13 22 65.`,
      201029: `To protect your security, we've logged you out.`,
      201030: 'To ensure your security, we had to log you out. Try logging in again.',
      201031: 'NAB Internet Banking is temporarily unavailable. Try again later.'
    },

    LANGUAGE: {
      labels: {
        SECURITY: 'Security',
        HELP: 'Help',
        CONTACT_US: 'Contact us',
        LOCATE_US: 'Locate us',
        COPYRIGHT_TEXT: '© National Australia Bank Limited',
        LOGIN_TIPS: 'loginTips',
        SEARCH: 'Search',
        SEARCH_CLOSE: 'Close search',
        NOTIFICATION: 'Notification',
        PRINT: 'Print',
        LOGOUT: 'Logout',
        MAIN_MENU: 'Main Menu',
        SKIP_TO_MAIN_CONTENT: 'Skip to main content'
      } as const,
      title: {
        NAB_TITLE: 'Internet Banking'
      } as const
    }
  },
  sdfdpilot: {
    ROOT_SPA: 'root-spa',
    INTERACTION_PREFIX: 'NABIB',
    LINKAGE_PATH: '/linkage',
    ACCOUNTS_SUMMARY_PATH: '/acctInfo_acctBal.ctl',
    MINIAPP_IFRAME: 'miniapp-iframe',
    NAVMGR_MEGAMENU_URL: '/menus',
    IDENTITY_INFO: 'identityInfo',
    SESSION_COOKIE: 'nabibSessionToken',
    DAF_SSC_HASH: 'daf-ssc',
    CUSTOMER_SESSION_ID: 'ibs-csId',
    IS_FIRST_LOGIN: 'isFirstLogin',
    IS_USER_LOGGED_IN: 'isUserLoggedIn',
    COOKIE_IS_AUTHENTICATED: 'ibs-isAuthenticated',
    SESSION_TIMESTAMP: `$nabibSessionTokenTimestamp`,
    CAN_SWITCH: 'can-switch',
    COOKIE_PATH: '/',
    TOKEN_TYPE_HINT: 'access_token',
    LOGIN_TIPS_MIN_HEIGHT: 40,
    LOADING_TEXT: 'Loading...',
    BC_BRAND_VALUE: 'Redstar',
    NOTIFICATION_SESSION_STORAGE_CONSTANT: 'hiddenNotificationCNs',
    NOTIFICATION_PAGE_VISITED: 'notificationPageVisited',
    CACHED_ALL_IB_NOTIFICATIONS: 'all_ib_notifications',
    CACHED_ACCOUNT_SUMMARY: 'ib_space_notification',
    CACHED_INVITATION_ACCOUNT: 'ib_space_invitation_account',

    // BRAND CONFIG
    BRAND_ID: 'ib',
    THEME_NAME: 'nab',
    DAF_TENANCY: 'ib',

    // IB classic form post
    FORM_METHOD_POST: 'POST',
    FORM_FIELD_TYPE_HIDDEN: 'hidden',

    LINKAGE_ERROR_EVENT_DISPLAY_CODE: '201029',
    PREVIEW_ACCESS_ERROR_EVENT_DISPLAY_CODE: '201031',

    LOCAL_STORAGE_ROLLOUT_FLAGS: 'ibs-rollout-features',

    // FLAGS
    LAUNCHDARKLY_FLAGS: {
      LOGIN_TIPS_IFRAME: 'login-tips-iframe',
      ACCESS_TO_SDFDPILOT_SHELL: 'access-to-sdfdpilot-shell',
      PREVIEW_CHECK_ENABLED: 'preview-check-enabled'
    } as const,

    DEFAULT_LOGIN_ERROR_MESSAGE: 'NAB Internet Banking is temporarily unavailable. Try again later.',
    ERROR_MESSAGE_MAP: {
      201015: `Your session has timed out due to inactivity - you'll need to log in again.`,
      201014: 'Your session has been disconnected. Please login again.',
      201001: 'The NAB ID or password you entered is incorrect. <br/>Remember, your password is case sensitive.',
      400106: `There are no accounts associated with your NAB ID. You'll need to give us a call on 13 22 65.`,
      15502:
        'Your access has been locked. You need to <a href="/nabib/password_reset.ctl" onKeyDown="javascript:event.cancelBubble=true" title="reset your password" class="link">reset your password</a>.',
      213033: `Your internet banking access is blocked. <br/>You'll need to call us on 13 22 65.`,
      201029: `To protect your security, we've logged you out.`,
      201030: 'To ensure your security, we had to log you out. Try logging in again.',
      201031: 'NAB Internet Banking is temporarily unavailable. Try again later.'
    },

    LANGUAGE: {
      labels: {
        SECURITY: 'Security',
        HELP: 'Help',
        CONTACT_US: 'Contact us',
        LOCATE_US: 'Locate us',
        COPYRIGHT_TEXT: '© National Australia Bank Limited',
        LOGIN_TIPS: 'loginTips',
        SEARCH: 'Search',
        SEARCH_CLOSE: 'Close search',
        NOTIFICATION: 'Notification',
        PRINT: 'Print',
        LOGOUT: 'Logout',
        MAIN_MENU: 'Main Menu',
        SKIP_TO_MAIN_CONTENT: 'Skip to main content'
      } as const
    }
  },
  ar: {
    ROOT_SPA: 'adviser-registration-miniapp',
    INTERACTION_PREFIX: 'ADVISERREGISTRATION',
    TOKEN_TYPE_HINT: 'access_token',
    USL_SESSION_COOKIE_ID: 'nabUslShell-sessionToken',
    USL_SESSION_COOKIE_IS_AUTHENTICATED: 'nabUslShell-isAuthenticated',
    COOKIE_PATH: '/',

    // BRAND CONFIG
    BRAND_ID: 'epi',
    THEME_NAME: 'nab',
    DAF_TENANCY: 'epi',

    // Miniapp on nav menu items
    CHANGE_PASSWROD_MENU_ITEM: 'manage-my-security',

    ERROR_MESSAGE_MAP: {
      201015: `Your session has timed out due to inactivity - you'll need to log in again.`,
      201014: 'Your session has been disconnected. Please login again.',
      201001: 'The NAB ID or password you entered is incorrect. <br/>Remember, your password is case sensitive.',
      400106: `There are no accounts associated with your NAB ID. You'll need to give us a call on 13 22 65.`,
      15502:
        'Your access has been locked. You need to <a href="/nabib/password_reset.ctl" onKeyDown="javascript:event.cancelBubble=true" title="reset your password" class="link">reset your password</a>.',
      213033: `Your internet banking access is blocked. <br/>You'll need to call us on 13 22 65.`,
      201029: `You have been disconnected. Try again.`,
      201030: 'To ensure your security, we had to log you out. Try logging in again.'
    },
    DEFAULT_LOGIN_ERROR_MESSAGE: 'NAB Adviser Registration is temporarily unavailable. Try again later.'
  },
  jbp: {
    ROOT_SPA: 'jbwere-prospect-miniapp',
    INTERACTION_PREFIX: 'JBWEREPROSPECT',
    TOKEN_TYPE_HINT: 'access_token',
    USL_SESSION_COOKIE_ID: 'nabUslShell-sessionToken',
    USL_SESSION_COOKIE_IS_AUTHENTICATED: 'nabUslShell-isAuthenticated',
    COOKIE_PATH: '/',

    // PROMPT LOGOUT CONFIG
    LOGOUT_PROMPT_TITLE: 'Are you sure you want to log out?',
    LOGOUT_PROMPT_CANCEL_TEXT: 'No, go back',
    LOGOUT_PROMPT_CONFIRM_TEXT: 'Yes, log out',

    // BRAND CONFIG
    BRAND_ID: 'jbwere',
    THEME_NAME: 'jbwere',
    DAF_TENANCY: 'jbwere',

    LOGIN_ERROR_MESSAGE_TITLE: 'We had a problem loading this page. Try again shortly.',
    GENERIC_ERROR_MESSAGE_DESCRIPTION: 'For support contact our Investor Services team on 1300 366 790.',

    ERROR_MESSAGE_MAP: {
      201015: `Your session has timed out due to inactivity - you'll need to log in again.`,
      201014: 'Your session has been disconnected. Please login again.',
      201001: 'The NAB ID or password you entered is incorrect. <br/>Remember, your password is case sensitive.',
      400106: `There are no accounts associated with your NAB ID. You'll need to give us a call on 13 22 65.`,
      15502:
        'Your access has been locked. You need to <a href="/nabib/password_reset.ctl" onKeyDown="javascript:event.cancelBubble=true" title="reset your password" class="link">reset your password</a>.',
      213033: `Your internet banking access is blocked. <br/>You'll need to call us on 13 22 65.`,
      201029: `You have been disconnected. Try again.`,
      201030: 'To ensure your security, we had to log you out. Try logging in again.'
    },
    DEFAULT_LOGIN_ERROR_MESSAGE: 'JBWere Prospect is temporarily unavailable. Try again later.'
  },
  sdfd: {
    ROOT_SPA: 'root',
    INTERACTION_PREFIX: 'SDFD',
    TOKEN_TYPE_HINT: 'access_token',
    NAVMGR_MEGAMENU_URL: '/menus',
    USL_SESSION_COOKIE_ID: 'nabUslShell-sessionToken',
    USL_SESSION_COOKIE_IS_AUTHENTICATED: 'nabUslShell-isAuthenticated',
    COOKIE_PATH: '/',

    // BRAND CONFIG
    BRAND_ID: 'epi',
    THEME_NAME: 'nab',
    DAF_TENANCY: 'epi',

    ERROR_MESSAGE_MAP: {
      201015: `Your session has timed out due to inactivity - you'll need to log in again.`,
      201014: 'Your session has been disconnected. Please login again.',
      201001: 'The NAB ID or password you entered is incorrect. <br/>Remember, your password is case sensitive.',
      400106: `There are no accounts associated with your NAB ID. You'll need to give us a call on 13 22 65.`,
      15502:
        'Your access has been locked. You need to <a href="/nabib/password_reset.ctl" onKeyDown="javascript:event.cancelBubble=true" title="reset your password" class="link">reset your password</a>.',
      213033: `Your internet banking access is blocked. <br/>You'll need to call us on 13 22 65.`,
      201029: `You have been disconnected. Try again.`,
      201030: 'To ensure your security, we had to log you out. Try logging in again.'
    },
    DEFAULT_LOGIN_ERROR_MESSAGE: 'NAB SDFD is temporarily unavailable. Try again later.'
  },
  common: {
    // Domains and Organisation
    NAB: 'nab',
    UNIVERSAL_SHELL: 'universal-shell',

    // Paths
    PATH_LOGIN: '/login',

    // Tenants
    IB_TENANT: 'ib',
    NAB_PORTAL_PAY_TENANT: 'nabportalpay',
    ADVISER_REGISTRATION_TENANT: 'adviserregistration',
    JBWERE_PROSPECT_TENANT: 'jbwereprospect',
    SDFD: 'sdfd',
    SDFDPILOT: 'sdfdpilot',

    // Universal Shell
    USL_SESSION_COOKIE_ID: 'nabUslShell-sessionToken',
    USL_SESSION_COOKIE_IS_AUTHENTICATED: 'nabUslShell-isAuthenticated',
    USL_SESSION_COOKIE_DCI: 'nabUslShell-dci',
    DAF_DCI_HASH: 'dci',
    BEARER_TOKEN: 'bearerToken',
    CUSTOMER_USER_TYPE: 'customer',
    BANKER_USER_TYPE: 'banker',
    COOKIE_PATH: '/',
    TOKEN_TYPE_HINT: 'access_token',

    //Identity stub
    STUB_VALIDATION_MESSAGE: 'Enter your email',
    LOGIN_BUTTON_LABEL: 'Login',
    ERROR_HEADING: 'Something went wrong',

    // Messages
    LOGOUT_CONFIRMATION_MESSAGE: 'Thank you for banking with NAB.\nAre you sure you want to logout?',
    GENERIC_ERROR_MESSAGE_TITLE: 'We had a problem loading this page',
    LOGIN_ERROR_MESSAGE_TITLE: 'We had a problem loading the login screen.',
    GENERIC_ERROR_MESSAGE_DESCRIPTION: 'Try again shortly.',

    // Notification banner
    BANNER_CHARACTERS_LIMITED: 1000,

    // FLAGS
    LAUNCHDARKLY_FLAGS: {
      PRE_LOGIN_DISPLAY_BANNER: 'pre-login-display-banner',
      PRE_LOGIN_BANNER_MESSAGE: 'pre-login-banner-message'
    } as const,

    // Preview DOMAIN
    IB_PREVIEW_HOSTNAME: 'ibpreview.nab.com.au'
  },
  globalAuthErrorCodes: {
    API_452: 'API-452',
    API_462: 'API-462',
    API_465: 'API-465',
    API_446: 'API-446',
    API_449: 'API-449',
    SESSION_EXPIRED_401: '401-session expired',
    INVALID_OR_MISSING_TOKEN_401: '401-invalid or missing token',
    MULTIPLE_CONCURRENT_LOGINS_DETECT_401: '401-multiple concurrent logins detected',
    REQUEST_IS_FORBIDDEN_403: '403-request is forbidden',
    USER_IS_LOCKED_403: '403-user is locked',
    USER_IS_BLOCKED_403: '403-user is blocked',
    DA_669: 'DA-669'
  }
};
