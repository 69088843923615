import { Container } from '@nab/nui-react';
import { SpaProps } from '@nab/x-spa-react';
import GlobalHeader from 'components/GlobalHeader/GlobalHeader';
import { GenericError, Viewport } from 'components/common';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tenant } from 'tenants/_strategy';

import { SdfdStrategy } from 'tenants/sdfd';

import { StyledContentContainer } from './SdfdSpa.style';

export const SdfdSpa: React.FC<SpaProps> = ({ activeAppInstance, context, initError }) => {
  const tenant = Tenant.instance() as SdfdStrategy;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitError, setIsInitError] = useState(false);

  useEffect(() => {
    if (initError) {
      setIsInitError(true);
    }
  }, [initError]);

  const mainRef = useRef(null);

  const menu = useMemo(() => context?.menu || [], [context?.menu]);
  const viewport = useMemo(() => {
    if (isInitError) {
      return <GenericError />;
    }

    const isHomeItemHidden =
      activeAppInstance?.route?.ownPattern === '/home' && activeAppInstance?.appConfig?.shellConfig?.hidden === true;

    return (
      <Container>
        <StyledContentContainer>
          {!isHomeItemHidden && (
            <Viewport activeAppInstance={activeAppInstance} errorSlotOverride={() => <GenericError />} />
          )}
        </StyledContentContainer>
      </Container>
    );
  }, [activeAppInstance, isInitError]);

  return (
    <>
      <GlobalHeader
        activeAppInstance={activeAppInstance}
        mainRef={mainRef}
        navMgrMenu={menu}
        isAuthenticated={isAuthenticated}
      />
      <main ref={mainRef} tabIndex={-1} data-testid="launchpad-spa" style={{ height: '100%' }}>
        {viewport}
      </main>
    </>
  );
};
