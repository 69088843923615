import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import mergeWith from 'lodash/mergeWith';

/**
 * Deeply merges multiple objects using Lodash's mergeWith
 * @param objects - Array of objects to merge, first object is the target
 * @returns A new merged object without modifying the originals
 *
 * Features:
 * - Preserves all properties from earlier objects that don't exist in later objects
 * - Overwrites primitive values from earlier objects with values from later objects
 * - Recursively merges nested objects
 * - Creates a deep clone to avoid modifying original objects
 * - Handles nested properties of any depth
 * - Supports merging multiple objects in sequence
 *
 * Example:
 * const obj1 = { a: { b: 1 } };
 * const obj2 = { a: { c: 2 } };
 * const obj3 = { a: { d: 3 } };
 * deepMerge(obj1, obj2, obj3) // Result: { a: { b: 1, c: 2, d: 3 } }
 */
export function deepMerge(...objects: any[]): any {
  // Return empty object if no arguments
  if (objects.length === 0) return {};

  // Return clone of first object if only one argument
  if (objects.length === 1) return cloneDeep(objects[0]);

  // Custom merger function to handle specific merge logic
  const customMerger = (objValue: any, srcValue: any) => {
    if (isObject(objValue) && isObject(srcValue) && !Array.isArray(objValue) && !Array.isArray(srcValue)) {
      return deepMerge(objValue, srcValue);
    }
    return srcValue;
  };

  // Reduce all objects into a single merged object
  return objects.reduce((result, current) => {
    // Create deep clones to avoid modifying originals
    const clonedResult = cloneDeep(result);
    const clonedCurrent = cloneDeep(current);

    return mergeWith(clonedResult, clonedCurrent, customMerger);
  });
}

// Usage examples:
// Merge two objects
// const merged = deepMerge(objA, objB);

// Merge multiple objects
// const merged = deepMerge(objA, objB, objC, objD);

// Merge array of objects
// const objects = [objA, objB, objC, objD];
// const merged = deepMerge(...objects);
