import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Container } from '@nab/nui-react';
import { SpaProps } from '@nab/x-spa-react';

import GlobalHeader from 'components/GlobalHeader/GlobalHeader';
import { GenericError, OverlayProvider, TenantThemeProvider, Viewport } from 'components/common';

import { useLogoutCleanup } from 'hooks/useLogout';
import { Tenant } from 'tenants/_strategy';
import { constants } from 'tenants/constants';
import { JbpStrategy } from 'tenants/jbwere-prospect';
import { ERROR_CODE } from 'tenants/nab-portal-pay/types';

import { GenericErrorWrapper, StyledContentContainer, StyledMainWrapper } from './JBWereSpa.style';

export const JBWereSpa: React.FC<SpaProps> = ({ activeAppInstance, context, initError }) => {
  const tenant = Tenant.instance() as JbpStrategy;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitError, setIsInitError] = useState(false);
  const mainRef = useRef(null);
  const { logoutUser } = useLogoutCleanup();

  useEffect(() => {
    const sessionIsValid = tenant.session.isValid();
    setIsAuthenticated(sessionIsValid);
    if (!sessionIsValid) {
      logoutUser(`/login?error=${ERROR_CODE.UNAUTHORISED}`);
    }
    // TODO: The dependencies should be empty to avoid infinite loop which crash app, need have a BAU ticket to investigate
  }, []);

  useEffect(() => {
    if (initError) {
      setIsInitError(true);
    }
  }, [initError]);

  const menu = useMemo(() => context?.menu || [], [context?.menu]);

  const viewport = useMemo(() => {
    const GenericErrorComp = (
      <GenericErrorWrapper>
        <GenericError
          errorTitle={constants.jbp.LOGIN_ERROR_MESSAGE_TITLE}
          errorDescription={constants.jbp.GENERIC_ERROR_MESSAGE_DESCRIPTION}
          hideErrorButton
        />
      </GenericErrorWrapper>
    );

    if (isInitError) return GenericErrorComp;

    return (
      <Container>
        <StyledContentContainer>
          <Viewport activeAppInstance={activeAppInstance} errorSlotOverride={() => GenericErrorComp} />
        </StyledContentContainer>
      </Container>
    );
  }, [activeAppInstance, isInitError]);

  return (
    <TenantThemeProvider>
      <OverlayProvider>
        <GlobalHeader
          activeAppInstance={activeAppInstance}
          mainRef={mainRef}
          navMgrMenu={menu}
          isAuthenticated={isAuthenticated}
        />
        <StyledMainWrapper ref={mainRef} tabIndex={-1} data-testid="jbwere-spa">
          {viewport}
        </StyledMainWrapper>
      </OverlayProvider>
    </TenantThemeProvider>
  );
};
