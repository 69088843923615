import { useEffect } from 'react';
import { useActions } from '@nab/nab-x-react';
import { useLogoutCleanup } from 'hooks/useLogout';
import { constants } from 'tenants/constants';
import { CLASSIC_ERROR_CODES, EVENTS, IAuthError } from 'tenants/types';

const GLOBAL_AUTH_ERRORS: { [key: string]: CLASSIC_ERROR_CODES } = {
  [constants.globalAuthErrorCodes.API_452]: CLASSIC_ERROR_CODES.UNAUTHORISED, // Multiple concurrent logins detected HTTP 400
  [constants.globalAuthErrorCodes.API_462]: CLASSIC_ERROR_CODES.UNAUTHORISED, // Forbidden HTTP 400
  [constants.globalAuthErrorCodes.API_465]: CLASSIC_ERROR_CODES.UNAUTHORISED, // User is blocked HTTP 403

  [constants.globalAuthErrorCodes.API_446]: CLASSIC_ERROR_CODES.RESET_PASSWORD, // User has been locked OR User is currently locked HTTP 400. Normally ask user to reset password
  [constants.globalAuthErrorCodes.API_449]: CLASSIC_ERROR_CODES.UNAUTHORISED, // Unauthorised HTTP 401
  [constants.globalAuthErrorCodes.INVALID_OR_MISSING_TOKEN_401]: CLASSIC_ERROR_CODES.UNAUTHORISED,
  [constants.globalAuthErrorCodes.SESSION_EXPIRED_401]: CLASSIC_ERROR_CODES.UNAUTHORISED,
  [constants.globalAuthErrorCodes.MULTIPLE_CONCURRENT_LOGINS_DETECT_401]: CLASSIC_ERROR_CODES.UNAUTHORISED,
  [constants.globalAuthErrorCodes.REQUEST_IS_FORBIDDEN_403]: CLASSIC_ERROR_CODES.UNAUTHORISED,
  [constants.globalAuthErrorCodes.USER_IS_LOCKED_403]: CLASSIC_ERROR_CODES.RESET_PASSWORD,
  [constants.globalAuthErrorCodes.USER_IS_BLOCKED_403]: CLASSIC_ERROR_CODES.UNAUTHORISED,

  // Stepup errors

  // The number of attempts to generate a credential exceeds the maximum allowed.
  generate_oauth_challenge_exceed_maximum_attempts: CLASSIC_ERROR_CODES.UNAUTHORISED,

  // The credential (user password or user token) has been disabled by the administrator.
  reauthentication_credentials_disabled: CLASSIC_ERROR_CODES.UNAUTHORISED,

  // The user password has been locked due to a number of failed login attempts.
  reauthentication_credentials_locked: CLASSIC_ERROR_CODES.UNAUTHORISED,

  // The user is self-locked using self-service.
  reauthentication_self_credentials_locked: CLASSIC_ERROR_CODES.UNAUTHORISED,

  // User account is disabled.
  reauthentication_user_disabled: CLASSIC_ERROR_CODES.UNAUTHORISED,

  [constants.globalAuthErrorCodes.DA_669]: CLASSIC_ERROR_CODES.UNAUTHORISED_TO_ENSURE_SECURITY,
  verify_user_response_max_verification_attempts_exceeded: CLASSIC_ERROR_CODES.UNAUTHORISED_TO_ENSURE_SECURITY
};

const isAuthErrorCode = code => !!GLOBAL_AUTH_ERRORS[code];
const handleErrorEvent = (e: IAuthError) => {
  const { code, httpStatusCode, description } = e;

  if (isAuthErrorCode(code)) {
    return GLOBAL_AUTH_ERRORS[code];
  }

  const kongCode = `${httpStatusCode}-${description}`;
  if (isAuthErrorCode(kongCode)) {
    return GLOBAL_AUTH_ERRORS[kongCode];
  }
};

export const useHandleApiAuthError = () => {
  const { addEventListener } = useActions();
  const { logoutUser } = useLogoutCleanup();

  useEffect(() => {
    const handleError = (e: IAuthError) => {
      const error = handleErrorEvent(e);
      if (error) {
        logoutUser(`${constants.common.PATH_LOGIN}?error=${error}`);
      }
    };
    const unsubscribes = [
      addEventListener(EVENTS.GLOBAL_ERROR_EVENT_NAME, handleError),
      addEventListener(`nabx:${EVENTS.GLOBAL_ERROR_EVENT_NAME}`, handleError)
    ];
    return () => {
      unsubscribes.forEach(fn => fn());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
