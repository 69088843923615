import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { MIN_HEIGH_CONTENT, WHITE } from '../theme';

import { MAX_LOGIN_WIDTH } from './constants';

export const StyledIdentityWrapper = styled.main<{ imageUrl: string }>`
  position: relative;
  min-height: ${({ theme }) => MIN_HEIGH_CONTENT(theme)};
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
`;

export const StyledLoginWrapper = styled.div<{ fullHeight?: boolean; isCenter?: boolean }>`
  position: relative;

  ${({ theme, isCenter }) =>
    isCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: ${MIN_HEIGH_CONTENT(theme)};
    `}
`;

export const StyledLogginInnerWrapper = styled.div`
  padding: ${rem(40)} 0;
`;

export const MenuAndViewport = styled.div`
  width: auto;
  max-width: ${MAX_LOGIN_WIDTH}px;
  background-color: ${WHITE};
  border-radius: ${({ theme }) => theme.modalRadius};
`;

// 570px belows is the rough estimate size of the miniapp identity
// we have the min height this way so it doesn't cause a stretch of the bg image
// when the miniapp renders
export const AppViewport = styled.div`
  flex: 1;
  position: relative;
  width: 100%;

  // This ensures apps are no longer displayed once they start shutting down
  > [aria-hidden='true'] {
    display: none;
  }

  .link {
    color: ${({ theme }) => theme.color.primary};
  }
`;
