import { IHeaderConfig } from 'tenants/_strategy';
import { HeaderEventName, MenuItemType } from '@nab/nui-react-molecule';
import { LogOutIcon } from '@nab/nui-react';
import JBWere from 'assets/images/jbwere.svg';

export const headerConfig: IHeaderConfig = {
  headerTitle: {
    alt: 'JB Were',
    title: undefined,
    redirectUrl: 'https://www.jbwere.com.au/'
  },
  headerLogo: {
    desktop: JBWere,
    mobile: JBWere
  },
  tabTitle: 'JB Were',
  hideAppTitle: false,
  preLoginItems: [],
  postLoginItems: [
    {
      title: 'Logout',
      type: MenuItemType.BUTTON,
      eventName: HeaderEventName.LOGOUT,
      showBtnIconOnSmScreen: true,
      buttonIcon: LogOutIcon,
      extraProps: {
        variant: 'secondary',
        color: 'secondary',
        iconToRight: true,
        icon: LogOutIcon
      }
    }
  ],
  hideNavMenu: true
};
