import React, { createContext, Fragment, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';

interface OverlayActionProps {
  close: () => void;
}

type OverlayRenderFn = (actions: OverlayActionProps) => ReactNode;

export interface OverlayContext {
  renderRoot: HTMLElement;
  present: (render: OverlayRenderFn) => void;
}

export interface PromptProviderProps {
  renderRoot?: HTMLElement;
  children?: ReactNode;
}

export const OverlayContext = createContext<OverlayContext>(null);

export const useOverlay = () => {
  return useContext(OverlayContext);
};

export const OverlayProvider: React.FC<PromptProviderProps> = props => {
  const count = useRef(0);

  const [overlays, setOverlays] = useState<Record<string, ReactNode>>({});

  const present = useCallback(
    (render: OverlayRenderFn) => {
      count.current += 1;

      const id = count.current;

      const node = render({
        close: () => {
          setOverlays(current => {
            const next = { ...current };
            delete next[id];
            return next;
          });
        }
      });

      setOverlays(current => ({ ...current, [id]: node }));
    },
    [setOverlays, count]
  );

  const contextValue = useMemo(
    (): OverlayContext => ({ present, renderRoot: props.renderRoot }),
    [present, props.renderRoot]
  );

  const fragments = useMemo(
    () => Object.entries(overlays).map(([id, node]) => <Fragment key={id}>{node}</Fragment>),
    [overlays]
  );

  return (
    <OverlayContext.Provider data-testid="overlay-provider" value={contextValue}>
      <Fragment>{props.children}</Fragment>
      {fragments}
    </OverlayContext.Provider>
  );
};
