import { ContextConfig, ReactRootInitConfig } from '@nab/x-spa-react';
import { spaContextProvider } from 'lib/navManagerHelpers';
import { IHeaderConfig, TenantCore } from 'tenants/_strategy';
import { constants } from 'tenants/constants';

import { headerConfig, initConfig } from '../configs';

export class Core extends TenantCore {
  public override async rootInitConfig(): Promise<ReactRootInitConfig> {
    return await initConfig();
  }

  public override headerConfig(): IHeaderConfig {
    return headerConfig;
  }

  public override async spaContextProvider(contextId: string): Promise<ContextConfig> {
    return spaContextProvider(contextId === constants.npp.ROOT_SPA, contextId);
  }
}
