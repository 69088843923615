/* eslint-disable @typescript-eslint/no-unused-vars */
import { IUser } from '@nab/x-types';
import { ITenantHelpers } from 'tenants/_strategy';

export class TenantHelpers implements ITenantHelpers {
  public getErrorMessage(code: string | number): string {
    throw new Error('Method not implemented.');
  }

  public isPreviewEnv(user: IUser): boolean {
    return false;
  }
}
