import { SpaProps, useActions, useUser } from '@nab/x-spa-react';
import GlobalHeader from 'components/GlobalHeader/GlobalHeader';
import { GenericError, Viewport } from 'components/common';
import { useLogoutCleanup } from 'hooks/useLogout';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Tenant } from 'tenants/_strategy';
import { constants } from 'tenants/constants';
import { NppStrategy } from 'tenants/nab-portal-pay';
import { ERROR_CODE } from 'tenants/nab-portal-pay/types';

import { StyledContentContainer } from './NabPortalPaySpa.style';

export const NabPortalPaySpa: React.FC<SpaProps> = ({ activeAppInstance, context, initError }) => {
  const user = useUser();
  const mainRef = useRef(null);
  const { logoutUser } = useLogoutCleanup();
  const { addEventListener } = useActions();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitError, setInitError] = useState(false);
  const sessionIsValid = Tenant.instance().session.isValid();

  useEffect(() => {
    if (initError) {
      setInitError(true);
    }
  }, [initError]);

  useEffect(() => {
    if (!sessionIsValid) {
      logoutUser(`/login?error=${ERROR_CODE.UNAUTHORISED}`);
    }

    // TODO: The dependencies should be empty to avoid infinite loop which crash app, need have a BAU ticket to investigate
  }, []);

  useEffect(() => {
    const tenant = Tenant.instance() as NppStrategy;

    const paymentMiniappEvents = Object.values(tenant.events.ref.payment).map(eventName => {
      return addEventListener(eventName, event => tenant.events.handleEventRoute(event, eventName));
    });

    const profileMiniappEvents = Object.values(tenant.events.ref.profile).map(eventName => {
      return addEventListener(eventName, event => tenant.events.handleEventRoute(event, eventName));
    });

    const payeeMiniappEvents = Object.values(tenant.events.ref.payee).map(eventName => {
      return addEventListener(eventName, event => tenant.events.handleEventRoute(event, eventName));
    });

    const walletMiniappEvents = Object.values(tenant.events.ref.wallet).map(eventName => {
      return addEventListener(eventName, event => tenant.events.handleEventRoute(event, eventName));
    });

    return () => {
      paymentMiniappEvents.forEach(unsubscribe => unsubscribe());
      profileMiniappEvents.forEach(unsubscribe => unsubscribe());
      payeeMiniappEvents.forEach(unsubscribe => unsubscribe());
      walletMiniappEvents.forEach(unsubscribe => unsubscribe());
    };
  }, [addEventListener, logoutUser]);

  const errorSlotOverride = useCallback(() => {
    user.emitEvent({
      eventType: 'page-view',
      data: {
        action: 'error-displayed',
        section: 'account-summary|error',
        sectionId: 'accountSummary|error',
        errorType: 'screen',
        error: `${constants.common.GENERIC_ERROR_MESSAGE_TITLE}. ${constants.common.GENERIC_ERROR_MESSAGE_DESCRIPTION}`,
        // @ts-ignore
        channel: window.digitalData.components.channel
      }
    });
    return <GenericError />;
  }, [user]);

  useEffect(() => {
    isAuthenticated && Tenant.instance().effects.updateAnalyticsData();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!!activeAppInstance && isAuthenticated) {
      user.emitEvent({
        eventType: 'page-view',
        data: {
          section: 'account-summary',
          // @ts-ignore
          channel: window.digitalData.components.channel
        }
      });
    }
  }, [activeAppInstance, isAuthenticated, user]);

  const viewport = useMemo(() => {
    return (
      <>
        {!isInitError ? (
          <StyledContentContainer>
            <Viewport activeAppInstance={activeAppInstance} errorSlotOverride={errorSlotOverride} />
          </StyledContentContainer>
        ) : (
          <GenericError />
        )}
      </>
    );
  }, [activeAppInstance, errorSlotOverride, isInitError]);

  useEffect(() => {
    setIsAuthenticated(Tenant.instance().session.isValid());
  }, []);

  const menu = context?.menu || [];
  return (
    <>
      <GlobalHeader
        activeAppInstance={activeAppInstance}
        mainRef={mainRef}
        navMgrMenu={menu}
        isAuthenticated={isAuthenticated}
      />
      <main ref={mainRef} tabIndex={-1} data-testid="nab-portal-pay-spa" style={{ height: '100%' }}>
        {viewport}
      </main>
    </>
  );
};
