import { NavItem } from '@nab/x-types';
import Cookies from 'js-cookie';
import { constants } from 'tenants/constants';

export const filterByFeatureFlag = (items: NavItem[]): NavItem[] =>
  items.filter(item => {
    // 1. there is no flag, enable by default
    if (!item.featureFlag?.name) {
      return true;
    }

    // 2. there is miniappEnabled flag and it's true, enable
    if (item.featureFlag?.name === 'miniappEnabled' && item.featureFlag?.default === true) {
      return true;
    }

    // 3. there is a rollout flag, read from Cookies which is output by Classic
    // the flag should have the format "<miniapp-id>-rollout", e.g.
    // miniapp-statement-preferences-rollout
    if (item.featureFlag?.name?.endsWith('-rollout')) {
      const itemName = item.featureFlag?.name;
      const isItemOn = item.featureFlag.default === true;
      const cookiesValue = Cookies.get(itemName);
      // save the rollout flags into localStorage, so we can clean it on logout/login page
      const rolloutFeatures = getMiniappRolloutFlagsFromLocalStorage();
      if (!rolloutFeatures.includes(itemName)) {
        rolloutFeatures.push(itemName);
        localStorage.setItem(constants.ib.LOCAL_STORAGE_ROLLOUT_FLAGS, JSON.stringify(rolloutFeatures));
      }

      return (isItemOn && cookiesValue === 'true') || (!isItemOn && cookiesValue !== 'true');
    }

    return false;
  });

/**
 * Rollout feature flags in local storage is a JSON string array, e.g.:
 * '["miniapp-statement-preferences-rollout","miniapp-pay-anyone-rollout"]'
 *
 * This function verify a few things:
 * 1. local storage value is a valid JSON string
 * 2. it's parsed as an array
 * 3. the items of the array are all string type
 */
export const getMiniappRolloutFlagsFromLocalStorage = (): string[] => {
  const rolloutFlagsRaw = localStorage.getItem(constants.ib.LOCAL_STORAGE_ROLLOUT_FLAGS);
  let rolloutFeatures: string[];

  try {
    const initialParsedValue = JSON.parse(rolloutFlagsRaw);
    rolloutFeatures = Array.isArray(initialParsedValue) ? initialParsedValue : [];
  } catch (e) {
    // in case localStorage is corrupted
    rolloutFeatures = [];
    console.error(e);
  }

  if (rolloutFeatures.length) {
    rolloutFeatures = rolloutFeatures.filter(item => typeof item === 'string');
  }

  return rolloutFeatures;
};
