import { MIN_HEIGH_CONTENT } from 'components/theme';
import styled from 'styled-components';

export const StyledContentContainer = styled.div`
  max-width: 503px;
  padding-top: 4px;
`;

export const StyledMainWrapper = styled.main<{ imageUrl: string }>`
  position: relative;
  min-height: ${({ theme }) => MIN_HEIGH_CONTENT(theme, true)};
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;

  @media screen and (max-width: ${({ theme }) => theme.grid.breakpoints.lg}) {
    min-height: ${({ theme }) => MIN_HEIGH_CONTENT(theme)};
  }
`;
