import { configMgr } from 'lib/configMgr';
import { Tenant, TenantEvents } from 'tenants/_strategy';
import { getShellActions } from '@nab/x-spa-react';

export class Events extends TenantEvents {
  public override async handleLogoutButton(logoutUser: Function): Promise<void> {
    const redirectUrlAfterLogout = configMgr.get('LOGOUT_REDIRECT_URL');

    if (Tenant.instance().effects.confirmLogout()) {
      logoutUser(redirectUrlAfterLogout);
    }
  }

  public override handleEventRoute(event: any, eventName: string) {
    const { navigate } = getShellActions();
    const routeTo = this.eventToRouteMap[eventName];

    if (routeTo) {
      navigate(routeTo);
    }
  }

  private get eventToRouteMap() {
    return {
      // ChangePassword Miniapp Routes
      [this.ref.changePassword.USER_CANCELLED]: `/portal/home`
    };
  }

  public get ref() {
    return {
      ...super.ref,
      /**
       * ChangePassword Miniapp
       */
      changePassword: {
        /**
         * Dispatched when clicking on Cancel button
         * Change password screen
         */
        USER_CANCELLED: `user_cancelled`
      }
    };
  }
}
