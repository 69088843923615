import { HeaderEventName, MenuItemType } from '@nab/nui-react-molecule';
import { LogOutIcon } from '@nab/nui-react';
import { IHeaderConfig } from 'tenants/_strategy';
import Star from 'assets/images/star.svg';
import StarMore from 'assets/images/star_more.svg';

export const headerConfig: IHeaderConfig = {
  headerTitle: {
    alt: 'Internet Banking',
    title: 'Internet Banking'
  },
  headerLogo: {
    desktop: StarMore,
    mobile: Star
  },
  tabTitle: 'NAB Internet Banking',
  hideAppTitle: true,
  preLoginItems: [
    {
      type: MenuItemType.DATE
    },
    {
      title: 'Help',
      displayTitle: true,
      type: MenuItemType.ANCHOR,
      target: '_self',
      url: 'https://www.nab.com.au/personal/online-banking/nab-internet-banking/sms-security'
    },
    {
      title: 'Security',
      displayTitle: true,
      type: MenuItemType.ANCHOR,
      target: '_blank',
      url: 'https://www.nab.com.au/about-us/security'
    },
    {
      title: 'Contact us',
      displayTitle: true,
      type: MenuItemType.ANCHOR,
      target: '_blank',
      url: 'https://www.nab.com.au/contact-us'
    },
    {
      title: 'Locate us',
      displayTitle: true,
      type: MenuItemType.ANCHOR,
      target: '_blank',
      url: 'https://www.nab.com.au/locations'
    }
  ],
  postLoginItems: [
    {
      title: 'Logout',
      type: MenuItemType.BUTTON,
      eventName: HeaderEventName.LOGOUT,
      showBtnIconOnSmScreen: true,
      buttonIcon: LogOutIcon
    }
  ]
};
