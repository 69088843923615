import React, { useState, useEffect } from 'react';
import { useActions, getShellActions } from '@nab/x-spa-react';
import { ButtonIcon, DeleteIcon } from '@nab/nui-react';
import styled from 'styled-components';

import { Tenant } from 'tenants/_strategy';
import { NppStrategy } from 'tenants/nab-portal-pay';

import { IbStrategy } from 'tenants/internet-banking';

import { configMgr } from 'lib/configMgr';

import EventLog from './EventLog';

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BIO_CATCH_CONTEXT_EVENT_NAME = 'ContextChange';

function useBioCatchContextChangeSubscription(contextChangedHandler: React.Dispatch<React.SetStateAction<any[]>>) {
  useEffect(() => {
    return window.addEventListener('message', e => {
      // Check if the event is being obtained from a known origin
      const allowedOrigins = configMgr.get('EVENT_ALLOWED_ORIGINS')?.split(',');

      if (!allowedOrigins.includes(e.origin.toString())) return;

      if (e.data.type === BIO_CATCH_CONTEXT_EVENT_NAME) {
        contextChangedHandler(prevEvents => [
          ...prevEvents,
          {
            timestamp: new Date().getTime(),
            name: BIO_CATCH_CONTEXT_EVENT_NAME,
            data: e.data
          }
        ]);
      }
    });
  }, [contextChangedHandler]);
}

const EventMonitor: React.FC = () => {
  const [events, setEvents] = useState([]);
  const { addEventListener, removeEventListener } = useActions();
  const { dispatchEvent } = getShellActions();

  dispatchEvent('test', {});

  useEffect(() => {
    const AppEvents = [...Object.values(Tenant.instance().events.ref.identity)];

    if (Tenant.instance()?.name === 'nabportalpay') {
      const tenant = Tenant.instance() as NppStrategy;

      AppEvents.push(...Object.values(tenant.events.ref.payment));
      AppEvents.push(...Object.values(tenant.events.ref.payee));
      AppEvents.push(...Object.values(tenant.events.ref.wallet));
      AppEvents.push(...Object.values(tenant.events.ref.profile));
    }

    if (Tenant.instance()?.name === 'ib') {
      const tenant = Tenant.instance() as IbStrategy;

      AppEvents.push(...Object.values(tenant.events.ref.linkage));
    }

    AppEvents.forEach(event => {
      addEventListener(event, eventData => {
        setEvents(prevEvents => [
          ...prevEvents,
          {
            timestamp: new Date().getTime(),
            name: event,
            data: eventData
          }
        ]);
      });
    });

    return () => {
      AppEvents.forEach(event => {
        removeEventListener(event);
      });
    };
  }, [addEventListener, removeEventListener]);

  useBioCatchContextChangeSubscription(setEvents);

  return (
    <>
      <EventLog events={events} />
      <Footer>
        <ButtonIcon
          label="Clear Events"
          icon={DeleteIcon}
          onClick={() => {
            setEvents([]);
          }}
          color={'primary'}
        />
      </Footer>
    </>
  );
};

export default EventMonitor;
