import { IUser } from '@nab/x-types';
import { Tenant, TenantEffects } from 'tenants/_strategy';

export class Effects extends TenantEffects {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async logout(user: IUser, redirectUri?: string, emitAnalytics: boolean = false): Promise<void> {
    try {
      await Tenant.instance().session.revoke();
    } catch (err) {
      console.error('Error: revoke error', err);
    } finally {
      Tenant.instance().session.clear();
      if (redirectUri) {
        window.location.href = redirectUri;
      }
    }
  }
}
