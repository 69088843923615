import {
  ContextConfig,
  getShellActions,
  NavManagerContextProvider,
  transformNavMenusToContexts,
  Menu
} from '@nab/x-spa-react';
import axios, { AxiosResponse } from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import { configMgr } from 'lib/configMgr';
import { EVENTS } from 'tenants/types';

import { constants } from 'tenants/constants';

import { Tenant } from 'tenants/_strategy';

import { CustomAppInstance, CustomSpaAppLoaderConfig, NavManagerResponse } from './navManagertypes';
import { filterByFeatureFlag } from './navManagerHelper';

export class NavManagerContextProviderExtended extends NavManagerContextProvider {
  public async getContexts(baseUrl): Promise<ContextConfig[]> {
    let result: AxiosResponse<NavManagerResponse>;
    try {
      result = await axios.get<NavManagerResponse>(`${baseUrl}${constants.ib.NAVMGR_MEGAMENU_URL}`, {
        timeout: configMgr.get('NAV_MANAGER_TIMEOUT'),
        headers: {
          Authorization: Tenant.instance().session.token()
        }
      });
    } catch (e: any) {
      const isTimeoutError = e.code === 'ECONNABORTED';
      getShellActions().dispatchEvent(EVENTS.GLOBAL_ERROR_EVENT_NAME, {
        httpStatusCode: e.request.status,
        code: isTimeoutError ? 'get_menus_timeout' : e.response?.data.error,
        description: e.response?.data.error_description
      });
      throw new Error(`Failed to retrieve menus for contexts: ${e.toString()}`);
    }

    try {
      const menuIdToContextIdMapping = result.data.menus.reduce((acc, menu) => {
        acc[menu.menuId] = menu.contextId;
        return acc;
      }, {});

      const filteredResults = result.data.menus.map(menuItem => ({
        ...menuItem,
        items: filterByFeatureFlag(menuItem.items)
      }));
      const contexts = transformNavMenusToContexts(filteredResults as unknown as Menu[]);
      contexts.forEach(context => {
        context.id = menuIdToContextIdMapping[context.id];
        context.apps.forEach(app => {
          const customSpaAppLoaderConfig = app as CustomSpaAppLoaderConfig;
          const { appMetaData, shellConfig: appShellConfig } = customSpaAppLoaderConfig;
          const { authConfig, printConfig } = appShellConfig?.mapping || {
            authConfig: {},
            printConfig: { canPrint: false }
          };
          const requestTokenOnLoad = authConfig?.requestTokenOnLoad ?? true;
          const isSeed = authConfig?.tokenExchangeEnabled !== true;
          const { title, shellConfig } = getShellConfigAndTitle(filteredResults, appMetaData.route);
          const authConfiguration = {
            requestTokenOnLoad,
            isSeed,
            tokenExchangeConfig: authConfig?.tokenExchangeConfig
          };
          customSpaAppLoaderConfig.authConfig = authConfiguration;
          appMetaData.shellConfig = shellConfig;
          appMetaData.title = title;
          appMetaData.printConfig = printConfig;
        });

        return context;
      });
      return contexts;
    } catch (e) {
      throw new Error(`Failed to transform menus to SPA context: ${e}`);
    }
  }
}

const getShellConfigAndTitle = (menus, route) => {
  const miniapps = menus
    .map(menu => {
      return menu.items.filter(item => item.config.type === 'MINIAPP');
    })
    .flat();
  const currentMiniapp = miniapps.find(miniapp => miniapp.config.route === route);
  const { shellConfig, title } = currentMiniapp;

  try {
    return {
      shellConfig: shellConfig ? JSON.parse(shellConfig) : null,
      title
    };
  } catch (error) {
    console.error('Error parsing shellConfig:', error);
    return {
      shellConfig: null,
      title
    };
  }
};

export function mapActiveAppInstance<TAppInstance extends CustomAppInstance | undefined>(
  activeAppInstance: TAppInstance
): TAppInstance {
  if (activeAppInstance) {
    const shellConfig = activeAppInstance.app?.shellConfig;
    const mapping = shellConfig?.mapping || {
      params: {},
      appData: {},
      appConfig: {},
      authConfig: {},
      extraRoutes: []
    };
    const ibCustomContext = cloneDeep(activeAppInstance.appData?.ibCustomContext);
    delete activeAppInstance.appData?.ibCustomContext;

    if (shellConfig?.disableShadowDom) {
      activeAppInstance.app.properties = activeAppInstance.app.properties || {};
      activeAppInstance.app.properties.isolation = 'lenient';
    }

    return {
      ...activeAppInstance,
      params: {
        ...activeAppInstance.params,
        ...mapping?.params,
        ...ibCustomContext?.params
      },
      appData: {
        ...activeAppInstance.appData,
        ...mapping?.appData,
        ...ibCustomContext?.appData
      },
      appConfig: {
        ...activeAppInstance.appConfig,
        ...mapping?.appConfig,
        ...ibCustomContext?.appConfig
      },
      authConfig: {
        ...activeAppInstance.authConfig,
        ...mapping?.authConfig,
        ...ibCustomContext?.authConfig
      },
      // extraRoutes will be an array of extra route other than main route that app goes to by navigation,
      // to ensure the menu item is highlighted when navigating to apps which are excluded from menu,
      // example reward-saver, and iSaver should be highlighting the products>products
      extraRoutes: mapping.extraRoutes
    };
  }
  return activeAppInstance;
}
