import { rem } from 'polished';
import styled from 'styled-components';

export const StyledFooterContent = styled.div`
  width: 100%;
  margin: 0px auto;
  padding: ${rem('32px')} 0;
  max-width: none;
`;

export const StyledRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: start;
  max-width: none;
  @media screen and (max-width: 1120px) {
    flex-direction: column;
    justify-content: start;
    gap: ${rem('32px')};
  }
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${rem('32px')};
`;

export const StyledMenuLink = styled.div`
  display: flex;
  align-items: start;
  gap: ${rem('24px')};
  @media screen and (max-width: 1120px) {
    justify-content: start;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledTextWrapper = styled.div`
  padding-top: ${rem('8px')};
`;

export const StyledText = styled.p`
  text-align: right;
  margin: 0px;
`;
