import { media, Modal } from '@nab/nui-react';
import styled from 'styled-components';

export const StyledModal = styled(Modal)<{ isCenterScreen?: boolean }>`
  ${({ theme }) =>
    theme.customStyle?.minHeightPrompt &&
    `
      min-height: ${theme.customStyle.minHeightPrompt};
    `}

  ${({ isCenterScreen }) =>
    isCenterScreen &&
    `
    margin: 0 auto;
    top: 35%;
  `}
`;

export const GridContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 1.25rem;
  margin-top: 8px;
`;

export const ButtonGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.25rem;

  ${media.md`
        flex-direction: row;
        justify-content: center;
    `}
`;

export const GridBody = styled.p`
  font-family: ${({ theme }) => theme.font.family.primary};
  margin-top: 0;
`;
